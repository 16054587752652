import React, { useEffect, useState } from 'react'
import Header from '../UI/Header/Header'
import Footer from '../UI/Footer/Footer'
import axios from 'axios';
import $, { isEmptyObject } from 'jquery';
import 'jquery-validation';
import { Add_Cart_Api, Add_Wishlist_Api, Filter_Products_Api, Get_Category_Data_Api, Get_Product_Data_Api, Get_SuperCategory_Data_Api, Get_Wishlist_Data_Api, Search_Products_Api, Get_SuperCategory_By_Product_Or_Category_Data_Api, Get_Cart_Api, Change_CartProduct_Que_Api, Get_Slider_Api, Delete_Product_Cart_Api } from '../../api';
import Loader from '../UI/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-toastify/dist/ReactToastify.css';

const HomePage = () => {
    let { filter_id } = useParams();
    const navigate = useNavigate();
    const location = JSON.parse(localStorage.getItem('location')) || {};
    const [data, setdata] = useState([]);
    const [cartData, setCartData] = useState([]);
    const [SuperCatagorydata, setSuperCatagorydata] = useState([]);
    const [AllCatagorydata, setAllCatagorydata] = useState([]);
    const [activeClass, setactiveClass] = useState('All');
    const [Wishlistdata, setWishlistdata] = useState([]);
    const [FilterValue, setFilterValue] = useState('1');
    const [token, setToken] = useState(null);
    const [sauce, setSauce] = useState(null);
    const [isJain, setIsJain] = useState({});
    const [SelectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [nearestOutlet, setNearestOutlet] = useState(location);
    const [SliderData, setSliderData] = useState([]);
    const [SliderHover, setSliderHover] = useState('');
    const [IsLoad, setIsLoad] = useState(false);
    const [searchText, setSearchText] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    let subTotal = 0;
    let indexes = 0;

    function Get_Slider_Api_Function() {
        axios({
            method: 'post',
            url: Get_Slider_Api,
            data: {
                store: nearestOutlet.id
            },
        }).then(response => {
            setSliderData(response.data.data);
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        Get_Slider_Api_Function();
    }, []);

    useEffect(() => {
        getCartData()
    }, [token])
    const getCartData = () => {
        if (token != null) {
            var config = {
                method: 'get',
                url: Get_Cart_Api,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };
            axios(config)
                .then((response) => {
                    let result = response.data.data;
                    setCartData(result.cart_data);
                    if (response.data.error == true) {
                        toast.error(response.data.msg);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
    const GetData = async () => {
        setIsLoad(true);
        var config = {
            method: 'post',
            url: Get_Product_Data_Api,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: {
                store: nearestOutlet._id ? nearestOutlet._id : nearestOutlet.id
            }
        };
        await axios(config)
            .then((response) => {
                setIsLoad(false);
                setdata(response.data.data)
                if (localStorage.getItem('authToken') != null) {
                    GetWishlistData();
                }
                if (response.data.error == true) {
                    toast.error(response.data.msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        if (localStorage.getItem('authToken') != null) {
            GetWishlistData();
        }
        var config = {
            method: 'get',
            url: Get_SuperCategory_Data_Api,
        };
        axios(config)
            .then((response) => {
                setSuperCatagorydata(response.data.data)
                if (response.data.error == true) {
                    toast.error(response.data.msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        var config = {
            method: 'get',
            url: Get_Category_Data_Api,
        };
        axios(config)
            .then((response) => {
                setAllCatagorydata(response.data.data);
                if (response.data.error == true) {
                    toast.error(response.data.msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        setToken(localStorage.getItem('authToken'));
    }, []);
    const GetWishlistData = () => {
        if (token != null) {
            var config = {
                method: 'get',
                url: Get_Wishlist_Data_Api,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                }
            };
            axios(config)
                .then((response) => {
                    setWishlistdata(response.data.data)
                    if (response.data.error == true) {
                        toast.error(response.data.msg);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    useEffect(() => {
        let data1 = data.map((item, key) => {
            if (typeof item.products != typeof undefined) {
                if (item.products.length > 0) {
                    let data2 = item.products.map((pro, index) => {
                        if (Wishlistdata.findIndex(x => x.productId == pro._id) >= 0) {
                            pro.islike = true;
                        } else {
                            pro.islike = false;
                        }
                        return pro;
                    })
                    data[key].products = data2;
                    return data[key];
                }
            } else {
                if (Wishlistdata.findIndex(x => x.productId == item._id) >= 0) {
                    item.islike = true;
                } else {
                    item.islike = false;
                }
                return item;
            }
        })
        setdata(data1);
    }, [Wishlistdata])

    const GetSuperCatagoryWiseData = (id) => {
        var config = {
            method: 'post',
            url: Get_SuperCategory_By_Product_Or_Category_Data_Api,
            data: {
                superCategoryId: id,
                storeId: nearestOutlet.id
            }
        };
        axios(config)
            .then((response) => {
                if (response.data.error == true) {
                    navigate('/product');
                } else {
                    setdata(response.data.data);
                    let active = SuperCatagorydata.filter(x => x.id == id);
                    if (active.length > 0) {
                        setactiveClass(active[0].superCategoryName);
                    }
                    GetWishlistData();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        if (typeof filter_id !== typeof undefined && filter_id != null) {
            GetSuperCatagoryWiseData(filter_id)
        } else {
            GetData();
        }
    }, [SuperCatagorydata])

    const AddToCartFunction = (e, id) => {
        if (localStorage.getItem('authToken')) {
            let isJain = $(`select[name="isJain_${id}"] option:selected`).val();
            if (typeof isJain == typeof undefined) {
                isJain = false;
            }
            let data = {
                product_id: id,
            }
            if (sauce != null) {
                data.addOns = [
                    {
                        _id: sauce,
                        quantity: 1
                    }
                ]
            }
            data.isJain = isJain
            var config = {
                method: 'post',
                url: Add_Cart_Api,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                data: data
            };
            axios(config)
                .then((response) => {
                    if (response.data.error == false) {
                        toast.success(response.data.msg);
                        $("#isJainModel .btn-close").click();
                        $("#AddOnsModel .btn-close").click();
                        getCartData();
                    } else {
                        toast.error(response.data.msg);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            window.$('#AddOnsModel').modal('hide');
            window.$('#loginModal').offcanvas('show');
        }
    }

    const selectJainModel = (item, type) => {
        $(".sauceSelect").addClass('d-none');
        setIsJain(item);
        if (type == 'jain') {
            window.$('#isJainModel').modal('show');
        } else {
            setSauce(null);
            window.$('#AddOnsModel').modal('show');
        }
    }

    const AddTowishlistFunction = (e, id) => {
        var config = {
            method: 'get',
            url: Add_Wishlist_Api + id,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        };
        axios(config)
            .then((response) => {
                if (response.data.error == false) {
                    GetWishlistData();
                    // toast.success(response.data.msg);
                    // Wishlistdata.forEach((i) => {
                    //     let data1 = '';
                    //     if(data.length > 0){
                    //         if (!isEmptyObject(data[0].products)) {
                    //             data1 = data[0].products.map((item, key) => {
                    //                 if (item._id == i.productId) {
                    //                     if(item.islike){
                    //                         item.islike = false;
                    //                     } else {
                    //                         item.islike = true;
                    //                     }
                    //                     return item
                    //                 } else {
                    //                     return item
                    //                 }
                    //             })
                    //         } else {
                    //             data1 = data.map((item, key) => {
                    //                 if (item._id == i.productId) {
                    //                     if(item.islike){
                    //                         item.islike = false;
                    //                     } else {
                    //                         item.islike = true;
                    //                     }
                    //                     return item
                    //                 } else {
                    //                     return item
                    //                 }
                    //             })
                    //         }
                    //     }
                    //     setdata(data1);
                    // })
                } else {
                    toast.error(response.data.msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        Search_api(searchText);
    }, [searchText])

    const Search_api = (search) => {
        if (search) {
            var config = {
                method: 'get',
                url: Search_Products_Api + search + '/' + nearestOutlet.id,
            };
            axios(config)
                .then((response) => {
                    setdata(response.data.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        if (e.target.checked) {
            setSelectedCheckboxes([...SelectedCheckboxes, value]);
        } else {
            setSelectedCheckboxes(SelectedCheckboxes.filter((item) => item !== value));
        }
    };

    const FilterShortByFunction = () => {
        var config = {
            method: 'post',
            url: Filter_Products_Api,
            data: { sortBy: FilterValue, category: SelectedCheckboxes, storeId: nearestOutlet.id, search: searchValue, superCategoryValue: activeClass }
        };
        axios(config)
            .then((response) => {
                setdata(response.data.data);
                $('.filter-modal-close').click();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const changeQuentity = (e, type) => {
        let id = e.target.id;
        let value = parseInt(e.target.value);
        if (type == 'add') {
            value += 1;
        } else {
            value -= 1;
        }
        var config = {
            method: 'post',
            url: Change_CartProduct_Que_Api,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: {
                id: id,
                quentity: value
            }
        };
        axios(config)
            .then((response) => {
                let data1 = cartData.map(item => {
                    if (item._id == response.data.data._id) {
                        item.quantity = response.data.data.quantity;
                        return item;
                    } else {
                        return item;
                    }
                })
                setCartData(data1);
                if (response.data.error == true) {
                    toast.error(response.data.msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const delteProductCart = (e) => {
        let id = e.target.id;
        var config = {
            method: 'delete',
            url: Delete_Product_Cart_Api,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            data: { id }
        };
        axios(config)
            .then((response) => {
                let data = cartData.filter(x => x._id != id);
                setCartData(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const clearAll = (e) => {
        setSelectedCheckboxes([]);
        setFilterValue(1);
        setSearchValue('');
        if (typeof filter_id !== typeof undefined && filter_id != null) {
            GetSuperCatagoryWiseData(filter_id)
        } else if(activeClass != 'All'){
            const SuperCategory = SliderData.filter(x => x.SuperCategory.superCategoryName == activeClass);
            if(SuperCategory.length > 0){
                GetSuperCatagoryWiseData(SuperCategory[0].superCategory_id)
            } else {
                GetData();
            }
        } else {
            GetData();
        }
    }

    return (
        <>
            <ToastContainer position='top-right' autoClose={3000} />
            {
                IsLoad == true ? <Loader /> :
                    <>
                        {!isEmptyObject(nearestOutlet) ? <Header fileName={'product'} nearestOutletParams={nearestOutlet} setNearestOutletParams={setNearestOutlet} setSearchTextParams={setSearchText} cartCount={cartData.length} /> : <Header fileName={'product'} nearestOutletParams={nearestOutlet} setNearestOutletParams={setNearestOutlet} setSearchTextParams={setSearchText} cartCount={cartData.length} />}
                        <div className="order">
                            <section className="menu-sec">
                                <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1">
                                    <div className="modal-dialog modal-lg" >
                                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="staticBackdropLabel">Filters</h5>
                                                <button type="button" className="btn-close filter-modal-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body p-3 p-sm-5">
                                                <div className="row">
                                                    <div className="col-lg-3 col-12">
                                                        <ul className="filter-tab-container nav nav-tabs">
                                                            <li>
                                                                <a className="tab pro filter-nav-link active" data-bs-toggle="tab" href="#Short-By">Short By</a>
                                                            </li>
                                                            {/* <li>
                                                                <a className="tab Cuisines filter-nav-link " data-bs-toggle="tab" href="#Cuisines">Cuisines</a>
                                                            </li> */}
                                                            <li>
                                                                <a className="tab More filters filter-nav-link" data-bs-toggle="tab" href="#More-filters">More filters</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-lg-9 col-12 mt-4 my-lg-0 tab-content">

                                                        <div className="tab-pane Short-By ms-lg-3 active" id='Short-By'>
                                                            <div className="col-md-12">
                                                                <div className="titles">
                                                                    <h5>Short By</h5>
                                                                    <ul className='short-by-filter-ul'>
                                                                        <li>
                                                                            <input type="radio" id="1" value="1" name="selector" checked={FilterValue == '1' ? true : false} onChange={(e) => { setFilterValue(e.target.value) }} />
                                                                            <label htmlFor="1">Popularity</label>
                                                                            <div className="check"></div>
                                                                        </li>

                                                                        <li>
                                                                            <input type="radio" id="2" value="2" name="selector" checked={FilterValue == '2' ? true : false} onChange={(e) => { setFilterValue(e.target.value) }} />
                                                                            <label htmlFor="2">Rating: High to Low</label>
                                                                            <div className="check"><div className="inside"></div></div>
                                                                        </li>
                                                                        <li>
                                                                            <input type="radio" id="3" value="3" name="selector" checked={FilterValue == '3' ? true : false} onChange={(e) => { setFilterValue(e.target.value) }} />
                                                                            <label htmlFor="3">Cost: Low to High</label>
                                                                            <div className="check"><div className="inside"></div></div>
                                                                        </li>
                                                                        <li>
                                                                            <input type="radio" id="4" value="4" name="selector" checked={FilterValue == '4' ? true : false} onChange={(e) => { setFilterValue(e.target.value) }} />
                                                                            <label htmlFor="4">Cost: High to Low</label>
                                                                            <div className="check"><div className="inside"></div></div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* <div className="tab-pane Cuisines" id='Cuisines'>
                                                            <div className="titles">
                                                                <h5>Cuisines</h5>
                                                                <ul className='Cuisines-filter-container mt-3'>
                                                                    {AllCatagorydata.map((item, index) => {
                                                                        return (
                                                                            <li className='col-6' key={index}>
                                                                                <label htmlFor="selected_cuisines">
                                                                                    <input type="checkbox" id={item.name} name="selected_cuisines[]" value={item._id} checked={SelectedCheckboxes.includes(item._id)} onChange={(e) => handleCheckboxChange(e)} />
                                                                                    {item.name}
                                                                                </label>
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </div> */}

                                                        <div className="tab-pane More-filters ms-lg-4" id='More-filters'>
                                                            <div className="titles">
                                                                <h5>More filters</h5>
                                                                <div className='col-12 mt-3'>
                                                                    <input type="text" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} className="form-control search" name='Search' placeholder="Search" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => clearAll()}>Clear All</button>
                                                <button type="button" className="btn btn-primary" onClick={() => FilterShortByFunction()}>Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="home">
                                    <div className="container py-5">
                                        <div className="heading">
                                            <div className='d-flex justify-content-between'>
                                                <h4>What's on your mind?</h4>
                                                <div className='custom-slider-btn d-flex'>
                                                    <button id="prevButton" className="slider-btn me-2" onClick={() => document.getElementById('menu').scrollBy({ left: -200, behavior: 'smooth' })}>
                                                        <span>
                                                            <i className="bi bi-arrow-left"></i>
                                                        </span>
                                                    </button>
                                                    <button id="forwardButton" className="slider-btn" onClick={() => document.getElementById('menu').scrollBy({ left: 200, behavior: 'smooth' })}>
                                                        <span>
                                                            <i className="bi bi-arrow-right"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="slider-body">
                                                <div className="menu" id="menu">
                                                    <div className="menu--wrapper w-100">
                                                        {SliderData.map((item, index) => {
                                                            return (
                                                                <div className="menu--item text-center" onMouseEnter={() => setSliderHover(item)} onMouseLeave={() => setSliderHover()} onClick={() => {
                                                                    GetSuperCatagoryWiseData(item.superCategory_id);
                                                                    setactiveClass(item.SuperCategory.superCategoryName);
                                                                }} key={index}>
                                                                    <div style={{ width: SliderHover == item || activeClass == item.SuperCategory.superCategoryName ? '145px' : '125px', height: SliderHover == item || activeClass == item.SuperCategory.superCategoryName ? '145px' : '125px' }}>
                                                                        <div className='slider-image-div'>
                                                                            <LazyLoadImage
                                                                                key={index}
                                                                                src={item.image}
                                                                                placeholderSrc="/assets/images/default_img.jpeg"
                                                                                alt={`Image`}
                                                                                effect="opacity"
                                                                                className="w-100"
                                                                                style={{
                                                                                    boxShadow: activeClass === item.SuperCategory.superCategoryName ? '0 0 15px #ffb81c' : '',
                                                                                    border: activeClass === item.SuperCategory.superCategoryName ? '5px solid #ffb81c' : ''
                                                                                }}
                                                                            />
                                                                            {/* <img src={item.image} alt="Image" style={{
                                                                                boxShadow: activeClass === item.SuperCategory.superCategoryName ? '0 0 15px #ffb81c' : '',
                                                                                border: activeClass === item.SuperCategory.superCategoryName ? '5px solid #ffb81c' : ''
                                                                            }} /> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className='text-dark mt-2' style={{ fontSize: '13px', fontWeight: 'bold' }}>
                                                                        <p style={{ color: activeClass === item.SuperCategory.superCategoryName ? '#ffb81c' : '' }}>{item.SuperCategory.superCategoryName}</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    {/* <div className='filter-main-container mb-3'> */}
                                    {/* </div> */}
                                    {/* <div className="title"> */}
                                    {/* <h4>{activeClass} {activeCatgaory  ? `> ${activeCatgaory}` : ''}</h4>
                                        <h6>{data.length} Items found</h6> */}
                                    {/* </div> */}
                                    <div className="row">
                                        <div className="col-12 col-md-12 col-lg-8 col-xl-9 product-section-wrapper position-relative">
                                            <div className="d-flex flex-wrap">
                                                {data.length > 0 ? data.map((item, index) => {
                                                    indexes += 1;
                                                    if (!isEmptyObject(item.products)) {
                                                        return (
                                                            <>
                                                                <div className='d-flex align-items-center justify-content-between mb-5 col-12 px-2'>
                                                                    <div className="title mb-0" key={indexes}>
                                                                        <h4>{item._id ? item._id : ''}</h4>
                                                                        <h6>{item.products.length} Items found</h6>
                                                                    </div>
                                                                    {index == 0 ? <div className="Filters-btn">
                                                                        <button className='d-flex' data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                                                            <img src='/assets/images/filter.png' style={{ width: "15px" }} className='me-2' />
                                                                            Filters
                                                                        </button>
                                                                    </div> : ''}
                                                                </div>
                                                                <div className="row col-12" key={indexes}>
                                                                    {/* <div className="col-12"> */}
                                                                        {/* <div className="row"> */}
                                                                            {item.products.length > 0 ? item.products.map((product, productIndex) => {
                                                                                indexes += 1;
                                                                                return (
                                                                                    <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4" style={{ paddingBottom: "30px" }} key={indexes}>
                                                                                        <div className="item-box">
                                                                                            <div className="item-img-box">
                                                                                                <div className="pic">
                                                                                                    <LazyLoadImage
                                                                                                        key={index}
                                                                                                        src={product.previewImage}
                                                                                                        placeholderSrc="/assets/images/default_img.jpeg"
                                                                                                        alt={`${product.name}`}
                                                                                                        effect="opacity"
                                                                                                        className="w-100"
                                                                                                    />
                                                                                                    {/* <img src={product.previewImage} className="w-100" /> */}
                                                                                                </div>
                                                                                                <div className="price">
                                                                                                    <h5>Rs.{product.rate}</h5>
                                                                                                    <button className="cart" onClick={(e) => { product.addons.length > 0 ? selectJainModel(product, 'addOns') : AddToCartFunction(e, product._id) }}>ADD</button>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="detail-box">
                                                                                                <h5>{product.name}</h5>
                                                                                                <p style={{ maxHeight: "150px" }}>
                                                                                                    {product.description.length > 0 ? product.description.split(' ').slice(0, 20).join(' ') : product.aliasDescription.length > 0 ? product.aliasDescription.split(' ').slice(0, 20).join(' ') : product.aliasDescription}
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className="veg">
                                                                                                {product.isNonVeg == true ? <img src="/assets/images/non-veg.png" /> : <img src="/assets/images/veg.png" />}
                                                                                            </div>
                                                                                            <div className="like" style={{ cursor: 'pointer' }} id={product._id} onClick={(e) => {
                                                                                                AddTowishlistFunction(e, product._id);
                                                                                            }}>
                                                                                                {product.islike ?
                                                                                                    <img src="/assets/images/like.png" /> : <img src="/assets/images/unlike.png" />}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }) : <div>No Products Avilable</div>}
                                                                        {/* </div> */}
                                                                    {/* </div> */}
                                                                </div>
                                                            </>
                                                        )
                                                    } else {
                                                        return (
                                                            <>
                                                                {indexes == 1 && (
                                                                    <div className="Filters-btn text-end col-12 pb-3 px-2">
                                                                        <button className='d-flex justify-self-end' data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                                                            <img src='/assets/images/filter.png' style={{ width: "15px" }} className='me-2' />
                                                                            Filters
                                                                        </button>
                                                                    </div>
                                                                )}
                                                                <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xl-4 px-2" style={{ paddingBottom: "30px" }} key={indexes}>
                                                                    <div className="item-box">
                                                                        <div className="item-img-box">
                                                                            <div className="pic">
                                                                                <LazyLoadImage
                                                                                    key={index}
                                                                                    src={item.previewImage}
                                                                                    placeholderSrc="/assets/images/default_img.jpeg"
                                                                                    alt={`${item.name}`}
                                                                                    effect="opacity"
                                                                                    className="w-100"
                                                                                />
                                                                                {/* <img src={item.previewImage} className="w-100" /> */}
                                                                            </div>
                                                                            <div className="price">
                                                                                <h5>Rs.{item.rate}</h5>
                                                                                <button className="cart" onClick={(e) => { item.addons.length > 0 ? selectJainModel(item, 'addOns') : AddToCartFunction(e, item._id) }}>ADD</button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="detail-box">
                                                                            <h5>{item.name}</h5>
                                                                            <p style={{ maxHeight: "150px" }}>
                                                                                {item.description.length > 0 ? item.description.split(' ').slice(0, 20).join(' ') : item.aliasDescription.length > 0 ? item.aliasDescription.split(' ').slice(0, 20).join(' ') : item.aliasDescription}
                                                                            </p>
                                                                        </div>
                                                                        <div className="veg">
                                                                            {item.isNonVeg == true ? <img src="/assets/images/non-veg.png" /> : <img src="/assets/images/veg.png" />}
                                                                        </div>
                                                                        <div className="like" style={{ cursor: 'pointer' }} id={item._id} onClick={(e) => {
                                                                            AddTowishlistFunction(e, item._id);
                                                                        }}>
                                                                            {item.islike ?
                                                                                <img src="/assets/images/like.png" /> : <img src="/assets/images/unlike.png" />}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                }) : <div>No Products Avilable</div>}
                                            </div >
                                        </div>
                                        <div className="col-lg-4 col-xl-3 d-none d-lg-block">
                                            <div className="cart-section w-100 float-end">
                                                {/* {data.length > 0 ? !isEmptyObject(data[0].products) ?
                                                    <div className="title w-100" style={{ height: '71px' }}>
                                                    </div> : '' : ''
                                                } */}
                                                {
                                                    cartData.length > 0 ?
                                                        <div className="cart-box p-3 col-12" style={{ borderRadius: '16px 16px 0 0' }}>
                                                            {cartData.map((item, index) => {
                                                                subTotal += (item.product.rate * item.quantity)
                                                                return (
                                                                    <>
                                                                        <div className='mb-3' style={{ borderBottom: '1px dotted #00000045' }} key={index} >
                                                                            <div className='row'>
                                                                                <div className='col-3 pe-0'>
                                                                                    <div className='img-wrapper position-relative'>
                                                                                        <img src={item.product.previewImage} className='w-100 rounded p-0 h-100 object-fit-cover' />

                                                                                        <div className="veg position-absolute" style={{ top: '5%', right: '60%' }}>
                                                                                            {item.product.isNonVeg == true ? <img src="/assets/images/non-veg.png" className='p-0' style={{ width: '85%' }} /> : <img src="/assets/images/veg.png" className='p-0' style={{ width: '85%' }} />}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-9'>
                                                                                    <h5 style={{ fontSize: '15px', fontWeight: 'bold' }}>{item.product.name != '' ? item.product.name : item.product.aliasName}</h5>

                                                                                    <p style={{ margin: '5px 0', fontSize: '12px' }}>{item.product.description.length > 0 ? item.product.description.split(' ').slice(0, 20).join(' ') : item.product.aliasDescription.length > 0 ? item.product.aliasDescription.split(' ').slice(0, 20).join(' ') : item.product.aliasDescription}</p>

                                                                                    <p>Type: <span style={{ color: 'green', fontWeight: 500 }}>{item.isJain ? 'Jain' : 'Regular'}</span></p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex my-3 align-items-center'>
                                                                                <div className='col-4 d-flex'>
                                                                                    <button className='cartBtn decBtn' type="button" onClick={(e) => changeQuentity(e, 'remove')} id={item._id} value={item.quantity} disabled={item.quantity == 1 ? 'disabled' : ''} name="button">-</button>
                                                                                    <input type="number" name="inputQty" id="" value={item.quantity} className='cartBtn inputQty' disabled />
                                                                                    <button className='cartBtn incBtn' type="button" name="button" onClick={(e) => changeQuentity(e, 'add')} value={item.quantity} id={item._id}>+</button>
                                                                                </div>
                                                                                <div className='col-4'>
                                                                                    <button className="order-btn text-dark" id={item._id} onClick={(e) => delteProductCart(e)} style={{ backgroundColor: '#ffb81c' }}>Remove</button>
                                                                                </div>
                                                                                <div className='col-4'>
                                                                                    <p style={{ float: 'right' }}>₹{item.product.rate * item.quantity}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                        :
                                                        <div className="cart-box col-12">
                                                            <img src="/assets/images/no-cart.png" />
                                                            <h4>Your cart is <br />empty</h4>
                                                            <p>Please add some items from the menu.</p>
                                                        </div>
                                                }
                                                {
                                                    cartData.length > 0 ?
                                                        <div className='d-flex col-12 p-3' style={{ borderRadius: '0 0 16px 16px', boxShadow: '0px 0px 5px 0px #00000017' }}>
                                                            <div className='col-6'>
                                                                <p style={{ fontWeight: 700, color: '#00000082', marginBottom: '5px' }}>Sub Total</p>
                                                                <p style={{ fontWeight: 'bolder' }}>₹{subTotal}</p>
                                                            </div>
                                                            <div className='col-6'>
                                                                <button className='cart w-100' onClick={() => navigate('/cart')}>Check Out</button>
                                                            </div>
                                                        </div> : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section >
                        </div >
                        <Footer />
                        <div className="modal fade" id="isJainModel" tabIndex="-1">
                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <div>
                                            <h1 className="modal-title fs-5" id="isJainModelLabel">{isJain.isNonVeg == true ? <img src="/assets/images/non-veg.png" /> : <img src="/assets/images/veg.png" />} {isJain.name}</h1>
                                            <p style={{ paddingLeft: '20px' }} >₹{isJain.rate}</p>
                                        </div>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <p style={{ fontSize: '25px', fontWeight: 500 }}>Choose From Preparation Style for {isJain.name} (optional)</p>
                                        <div className='checkbox text-start mt-4 mb-2'>
                                            <img src={isJain.isNonVeg == true ? "/assets/images/non-veg.png" : "/assets/images/veg.png"} />
                                            <input type="checkbox" name="isJain" id="isJain" />
                                            <label htmlFor="isJain" style={{ fontSize: '14px', marginLeft: '10px' }}>Jain</label>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="w-100 cart" onClick={(e) => AddToCartFunction(e, isJain._id, $("#isJain").prop('checked'))}>Add To Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="AddOnsModel" tabIndex="-1">
                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <div>
                                            <h1 className="modal-title fs-5" id="AddOnsModelLabel">{isJain.isNonVeg == true ? <img src="/assets/images/non-veg.png" /> : <img src="/assets/images/veg.png" />} {isJain.name}</h1>
                                            <p style={{ paddingLeft: '20px' }} >₹{isJain.rate}</p>
                                        </div>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <p style={{ fontSize: '25px', fontWeight: 500 }}>Choose From {isJain.addonsCategory ? isJain.addonsCategory.name : ''} for {isJain.name} (atleast {isJain.addonsCategory ? isJain.addonsCategory.minNoOfAddon : 1})</p>
                                        {
                                            isJain.addons && isJain.addons.map((item, index) => {
                                                return (
                                                    <div className='checkbox text-start mt-4 mb-2' key={index}>
                                                        <img src={isJain.isNonVeg == true ? "/assets/images/non-veg.png" : "/assets/images/veg.png"} />
                                                        <input type="checkbox" name={item.name == '' ? item.aliasName : item.name} id={item.name == '' ? item.aliasName : item.name} value={item._id} checked={sauce == (item._id)} onChange={(e) => { setSauce(e.target.value); $(".sauceSelect").addClass('d-none'); }} />
                                                        <label htmlFor={item.name == '' ? item.aliasName : item.name} style={{ fontSize: '14px', marginLeft: '10px' }}>{item.name == '' ? item.aliasName : item.name}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                        <p className="text-danger sauceSelect d-none"> select atleast 1 sauce </p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="w-100 cart" onClick={(e) => { sauce != null ? AddToCartFunction(e, isJain._id, false) : $('.sauceSelect').removeClass('d-none') }}>Add To Cart</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }

        </>
    )
}

export default HomePage