import React from 'react'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
    const navigate = useNavigate();
    return (
        <footer className="footer-sec">
            <div className="footer-header">
                <div className="container">
                    <div className="social-icon d-block d-sm-flex justify-content-center align-items-center">
                        <div>
                            <ul>
                                <li><a href='https://twitter.com/WokOnFireTweets/status/1644323347948535809' target="_blank"><img src={'/assets/images/twitter.png'} /></a></li>
                                <li><a href='/' target="#"><img src={'/assets/images/gplus.png'} /></a></li>
                                <li><a href='https://en-gb.facebook.com/wokonfire.in' target="_blank"><img src={'/assets/images/fb.png'} /></a></li>
                                <li><a href='https://www.instagram.com/wokonfire.in' target="_blank"><img src={'/assets/images/insta.png'} /></a></li>
                                <li><a href='https://in.linkedin.com/company/wok-on-fire-chinese-restaurtants' target="_blank"><img src={'/assets/images/linkedin.png'} /></a></li>
                            </ul>
                        </div>
                        <div className='mt-3 mt-sm-0'>
                            <ul>
                                <li className='d-none d-sm-inline-block'>|</li>
                                <li><a href='https://play.google.com/store/apps?hl=en_IN&pli=1' target="_blank"><img src={'/assets/images/android.png'} /></a></li>
                                <li><a href='https://www.apple.com/in/app-store/' target="_blank"><img src={'/assets/images/apple.png'} /></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="logo text-center">
                        <img src={'/assets/images/footer-logo.png'} />
                    </div>
                    <div className="row mb-5 justify-content-center">
                        {/* <div className="col-md-4 mt-5 my-md-0">
                            <div className="location">
                                <h6>FIND LOCATION NEAR YOU</h6>
                                <p>Surat  |  Vadodara  |  Ahemdabad  |  Anand  |  Vapi  |  Rajkot  |  Delhi  |  Noida  |  Bharuch  |  Limdi  |  Jamnagar</p>
                            </div>
                        </div>
                        <div className="col-md-4 mt-5 my-md-0">
                            <div className="location">
                                <h6>COMING SOON</h6>
                                <p>Gandhinagar  |  Junagadh  |  Bhavnagar  |  Nadiad  Gandhidham  |  Mehsana  |  Ahemedabad  |  Rajkot</p>
                            </div>
                        </div> */}
                        <div className="col-10 mt-5">
                            <div className="location">
                                <h6>FIND LOCATION NEAR YOU</h6>
                                <p><strong style={{ color: 'red' }}>Surat</strong> - Parle Point | Adajan</p>
                                <p><strong style={{ color: 'red' }}>Vadodara</strong> - OP Road | Fatehgunj | Gotri Road | Sama Savli Road</p>
                                <p><strong style={{ color: 'red' }}>Ahemdabad</strong> - HL College Road | Rajpath Rangoli  Road | <strong style={{ color: 'red' }}>Anand</strong> - Sojitra Road </p>
                                <p><strong style={{ color: 'red' }}>Vapi</strong> - Chala Road | <strong style={{ color: 'red' }}>Rajkot</strong> - 150 Ft Ring Road</p>
                                <p><strong style={{ color: 'red' }}>Bharuch</strong> - College Road | <strong style={{ color: 'red' }}>Valsad</strong> - Hold On</p>
                            </div>
                        </div>
                        <div className="col-10 mt-5">
                            <div className="location">
                                <h6>COMING SOON</h6>
                                <p><strong>Gandhinagar</strong>  |  <strong>Junagadh</strong>  |  <strong>Bhavnagar</strong>  |  <strong>Gandhidham</strong>  |  <strong>Mehsana</strong>  |  <strong>Ahemdabad</strong>  |  <strong>Nadiad</strong></p>
                            </div>
                        </div>
                    </div>
                    <div className="inquiry d-flex justify-content-center">
                        <div className='inquiry-wrapper col-12 col-lg-9 col-xl-7 position-relative d-md-flex d-block justify-content-center'>
                            <div className='col-12 col-md-5 text-center mb-3 mb-md-0'>
                                <h6>OPEN FRANCHISE</h6>
                            </div>
                            <div className='col-12 col-md-7 col-lg-6'>
                                <h4>
                                    <a href="tel:+919512822522"> +91 95128 22522 </a>
                                    <span>|</span>
                                    <a href="tel:+919925183333"> +91 99251 83333 </a>
                                    <br />
                                    <a href="mailto:franchise@wokonfire.in">
                                        franchise@wokonfire.in
                                    </a>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottam">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <p>
                                <a onClick={() => navigate('/disclaimer')}>Disclaimer</a> <span>  |  </span>
                                <a onClick={() => navigate('/policy')}>Privacy Policy</a> <span>  |  </span>
                                <a onClick={() => navigate('/faqs')}>Faq's</a> <span>  |  </span>
                                <a onClick={() => navigate('/terms&condition')}>Terms & Conditions</a> <span>  |  </span>
                                Feedback <span>  |  </span>
                                <a onClick={() => navigate('/about')}> About Us</a><span>  |  </span>
                                <a onClick={()=> navigate('/events')}> Media</a> <span>  |  </span>
                                <a onClick={()=> navigate('/events')}> Event</a>
                            </p>
                        </div>
                        <div className="col-md-6">
                            <p style={{ textAlign: "end" }}>© Copyright {(new Date().getFullYear())}  - MR MISCHIEF FOOD CO. All Rights Reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer