import React, { useEffect, useState, useRef } from 'react'
import Header from '../UI/Header/Header'
import Footer from '../UI/Footer/Footer'
import { useNavigate } from 'react-router-dom'


const Policy = () => {
    const navigate = useNavigate();
    const sectionRef = useRef();
    const [nearestOutlet, setNearestOutlet] = useState(JSON.parse(localStorage.getItem('location')) || {});

    useEffect(() => {
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    })

    return (
        <>
            <div ref={sectionRef}>
                <Header fileName={'policy'} nearestOutletParams={nearestOutlet} setNearestOutletParams={setNearestOutlet}  />
                <div className="policy">
                    <div className="container">
                        <button className="back" onClick={() => navigate('/')}>Back to Home</button>
                        <div className="title">
                            <h5>Privacy Policy</h5>
                        </div>
                        {/* <div className="policy-detail">
                            <p>1.	Information We Collect We may collect personal information from you, such as your name, email address, phone number, and payment information, when you use our website or application or place an order.</p>
                            <p>2.	How We Use Your Information We may use the information we collect from you to process your orders, communicate with you about your orders, and improve our products and services.</p>
                            <p>3.	Sharing of Information We may share your personal information with third-party service providers who assist us in operating our website and application or conducting our business.</p>
                            <p>4.	Security We take reasonable measures to protect the security of your personal information. However, no method of transmission over the internet or electronic storage is 100% secure.</p>
                            <p>5.	Third-Party Links Our website and application may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third-party sites or services.</p>
                            <p>6.	Children's Privacy Our website and application are not intended for children under the age of 13, and we do not knowingly collect personal information from children under the age of 13.</p>
                            <p>7.	Changes to Privacy Policy We reserve the right to modify this Privacy Policy at any time. Any changes will be effective immediately upon posting on our website or application. Your continued use of our website or application after any such changes constitutes your acceptance of the new Privacy Policy.</p>
                            <h4>8.	Cancellation and refund policy</h4>
                            <p>a.	There may be cases where WOF is either unable to accept your order or cancels the order, due to reasons including without limitation, technical errors, unavailability of the item(s) ordered, or any other reason attributable to WOF, Restaurant Partner/Store or Delivery Partner. In such cases, WOF shall not charge a cancellation charge from you. If the order is cancelled after payment has been charged and you are eligible for a refund of the order value or any part thereof, the said amount will be reversed to you.</p>
                            <p>b.	No replacement/refund / or any other resolution will be provided without the Restaurant's permission.</p>
                            <p>c.	Any complaint, with respect to the order which shall include instances but not be limited to food spillage, foreign objects in food, delivery of the wrong order or food and beverages or Products, poor quality, You will be required to share the proof of the same before any resolution can be provided.</p>
                            <p>d.	You shall not be entitled to a refund in case instructions placed along with the order are not followed in the form and manner You had intended. Instructions are followed by the Restaurant on a best-efforts basis.</p>
                            <p>e.	All refunds shall be processed in the same manner as they are received, unless refunds have been provided to You in the form of credits, refund amount will reflect in your account based on respective banks policies.</p>

                        </div> */}
                        <div className="policy-detail">
                            <h4>Privacy Policy of Website</h4>
                            <p>Wok On Fire operates the website, which provides the SERVICE.</p>
                            <p>This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service, the website.</p>
                            <p>If you choose to use our Service, then you agree to the collection and use of information in relation with this policy. The Personal Information that we collect are used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>
                            <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Website URL, unless otherwise defined in this Privacy Policy.</p>


                            <h4>Information Collection and Use</h4>
                            <p>For a better experience while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to your name, phone number, and postal address. The information that we collect will be used to contact or identify you.</p>


                            <h4>Log Data</h4>
                            <p>We want to inform you that whenever you visit our Service, we collect information that your browser sends to us that is called Log Data. This Log Data may include information such as your computer's Internet Protocol (“IP”) address, browser version, pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.</p>


                            <h4>Cookies</h4>
                            <p>Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your computer's hard drive.</p>
                            <p>Our website uses these “cookies” to collection information and to improve our Service. You have the option to either accept or refuse these cookies, and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our Service.</p>


                            <h4>Service Providers</h4>
                            <p>We may employ third-party companies and individuals due to the following reasons:</p>
                            <ul>
                                <li>To facilitate our Service;</li>
                                <li>To provide the Service on our behalf;</li>
                                <li>To perform Service-related services; or</li>
                                <li>To assist us in analysing how our Service is used.</li>
                            </ul>
                            <p>We want to inform our Service users that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>


                            <h4>Security</h4>
                            <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>


                            <h4>Links to Other Sites</h4>
                            <p>
                                Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
                                <br />
                                Children's Privacy
                                <br />
                                Our Services do not address anyone under the age of 13. We do not knowingly collect personal identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.

                            </p>

                            <h4>Changes to This Privacy Policy</h4>
                            <p>We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.</p>

                            <h4>Contact Us</h4>
                            <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Policy