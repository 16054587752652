import React, { useEffect, useRef, useState } from 'react'
import Header from '../UI/Header/Header'
import Footer from '../UI/Footer/Footer'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { isEmptyObject } from 'jquery'
import $ from 'jquery'
import { Get_Cart_Api, Get_Login_User_Api, Order_With_Upi_Api } from '../../api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Ordernow = () => {
    const navigate = useNavigate();
    const [subtotal, setsubtotal] = useState('0');
    const [total, settotal] = useState('0');
    const [tax, settax] = useState('0');
    const [userData, setUserData] = useState({});
    const [nearestOutlet, setNearestOutlet] = useState(JSON.parse(localStorage.getItem('location')) || {});
    const [Payment_Method, setPayment_Method] = useState('COD');
    const token = localStorage.getItem('authToken');
    const formRef = useRef();
    const GetData = () => {
        var config = {
            method: 'get',
            url: Get_Cart_Api,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
            .then((response) => {
                let result = response.data.data;
                setsubtotal(result.subtotal);
                settax(result.tax);
                settotal(result.total);
                if (response.data.error == true) {
                    toast.error(response.data.msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        GetData();
        var config = {
            method: 'get',
            url: Get_Login_User_Api,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
            .then((response) => {
                if (response.data.error == false) {
                    setUserData(response.data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    const OrderFunction = () => {
        if (Payment_Method == 'COD') {
            // alert('COD')
        }
        if (Payment_Method == 'Online_Payment') {
            var config = {
                method: 'post',
                url: Order_With_Upi_Api,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${token}`
                }
            };
            axios(config)
                .then((response) => {
                    if (response.data.encRequest) {
                        $('#encRequest').val(response.data.encRequest)
                        $('#access_code').val(response.data.accessCode)
                        $('#workingKey').val(response.data.workingKey)
                        $(formRef.current).submit();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
    return (
        <>
            <Header fileName={'orderNow'} nearestOutletParams={nearestOutlet} setNearestOutletParams={setNearestOutlet}  />
            {/* <form id="nonseamless" className='d-none' method="post" name="redirect" ref={formRef} action="https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction"> */}
            <form id="nonseamless" className='d-none' method="post" name="redirect" ref={formRef} action=" https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction">
                <input id="encRequest" name="encRequest" readOnly />
                <input name="access_code" id="access_code" readOnly />
                <input name="workingKey" id="workingKey" readOnly />
            </form>
            <div className="cart-detail">
                <div className="container">
                    <button onClick={() => navigate('/')} className="back">Back to Home</button>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="selected-item">
                                <h5>Payment Method</h5>
                                <div className="payment-box py-3">
                                    <div className="checkbox1">
                                        <input type="radio" id="COD" name="payment_method" value="COD" className='me-3' checked={Payment_Method == 'COD' ? true : false} onChange={(e) => { setPayment_Method('COD') }} />
                                        <label htmlFor="COD">Cash On Delhivery</label>
                                    </div>
                                    <img src="/assets/images/COD.png" style={{ width: "123px" }} />
                                </div>
                                <div className="payment-box">
                                    <div className="checkbox1">
                                        <input type="radio" id="Online_Payment" name="payment_method" value="Online_Payment" className='me-3' checked={Payment_Method == 'Online_Payment' ? true : false} onChange={(e) => { setPayment_Method('Online_Payment') }} />
                                        <label htmlFor="Online_Payment">Use Online Payment Opetion</label>
                                    </div>
                                    <img src="/assets/images/razorpay.png" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="heading">
                                <h4>Delivery Address</h4>
                            </div>
                            <div className="location-box">
                                <div className="row align-items-center justify-content-between">
                                    <div className="map-select">
                                        {userData.address !== undefined && userData.address.length > 0 ?
                                            <>
                                                <img src="/assets/images/location.png" />
                                                <div className="detail">
                                                    <h5>{userData.address[0].city}</h5>
                                                    <p>{userData.address[0].house_number}, {userData.address[0].address_line_1} </p>
                                                </div>
                                            </>
                                            : ''
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="heading">
                                <h4>Order Details</h4>
                            </div>
                            <div className="price-box">
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        <tr>
                                            <td>Sub Total</td>
                                            <td className="right">₹{subtotal}</td>
                                        </tr>
                                        <tr>
                                            <td>Discount</td>
                                            <td className="right">-</td>
                                        </tr>
                                        <tr>
                                            <td>Taxes and Charges</td>
                                            <td className="right">₹{tax}</td>
                                        </tr>
                                        <tr className="total">
                                            <td className="total">Amount Payable</td>
                                            <td className="total right">₹{total}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button className="order" onClick={() => OrderFunction()}>PROCCED TO PAY RS. {total}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Ordernow;