import React, { useEffect, useState } from 'react'
import Header from '../UI/Header/Header'
import { useNavigate } from 'react-router';
import { Get_Login_User_Api, Last_Payment_Api } from '../../api';
import axios from 'axios';
import { isEmptyObject } from 'jquery';
import moment from 'moment';

export default function WalletPaymentFailed() {
    const navigate = useNavigate();
    const [payment, setPayment] = useState({});
    const [userData, setUserData] = useState({});
    const [nearestOutlet, setNearestOutlet] = useState(JSON.parse(localStorage.getItem('location')) || {});
    const token = localStorage.getItem('authToken');
    useEffect(() => {
        var config = {
            method: 'get',
            url: Get_Login_User_Api,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
            .then((response) => {
                if (response.data.error == false) {
                    setUserData(response.data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        if (userData.id) {
            axios({
                method: 'get',
                url: Last_Payment_Api + userData.id,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(response => {
                let data = response.data.data;
                if (data.length > 0) {
                    setPayment(data[0]);
                } else {
                    navigate('/');
                }
            })
        }
    }, [userData]);
    return (
        <>
            <Header fileName={'walletPaymentFailed'} nearestOutletParams={nearestOutlet} setNearestOutletParams={setNearestOutlet}  />
            {!isEmptyObject(payment) &&
                <div className="payment">
                    <div className="container">
                        <div className="sucess-box bg-danger">
                            <img src="/assets/images/drop.png" />
                            <p>Your Transaction {payment.status}</p>
                        </div>
                        <div className="payment-detail-box">
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    <tr>
                                        <th>Order ID</th>
                                        <th>Order Date & Time</th>
                                        <th>Type</th>
                                    </tr>
                                    <tr>
                                        <td>{payment.order_id}</td>
                                        <td>{moment(payment.createdAt).format('DD MMM yyyy, hh:mm a')}</td>
                                        <td>Wallet balance</td>
                                    </tr>
                                    {/* <tr>
                                    <th>PAYMENT</th>
                                    <td>Online <br />Added to Wok On FIre Wallet Balance (₹{payment.amount})</td>
                                    <td></td>
                                </tr> */}
                                </tbody>
                            </table>
                            <button className="re-order-btn" onClick={() => navigate('/profile/wallet')}>BACK</button>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
