import React, { useEffect, useState } from 'react'
import Header from '../UI/Header/Header'
import Footer from '../UI/Footer/Footer'
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../UI/Loader';
import axios from 'axios';
import { Get_ContentData_Api, Get_Slider_Api, Get_SuperCategory_Data_Api, Get_All_Restaurant_Images } from '../../api'
import { ToastContainer, toast } from 'react-toastify';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-toastify/dist/ReactToastify.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const HomePage = () => {
    const navigate = useNavigate();
    const [superCategory, setSuperCategory] = useState([]);
    const [userLocation, setUserLocation] = useState({ latitude: null, longitude: null });
    const [nearestOutlet, setNearestOutlet] = useState(JSON.parse(localStorage.getItem('location')) || {});
    const [IsLoad, setIsLoad] = useState(false);
    const [topPics, setTopPics] = useState({
        image: '/assets/images/order-1.png',
        title: 'Top Pics',
        desc: 'New Summer Festival Dishes'
    });
    const [special, setSpecial] = useState({
        image: '/assets/images/order-2.png',
        title: 'WOF Special',
        desc: 'Lorem ipsum dolor sit ame..'
    });
    const [orderOnline, setOrderOnline] = useState({
        image: '/assets/images/order-online.png',
        title: 'Order Online',
        desc: 'This is Our Order Online Menu'
    });
    const [indo_Chinese_Express, setIndo_Chinese_Express] = useState({
        image: '/assets/images/menu-01.png',
        title: 'Indo Chinese Express',
        desc: 'This is Our Indo Chinese Express Title Menu',
        outletType: ''
    });
    const [SliderData, setSliderData] = useState([]);
    const [mainSliderData, setMainSliderData] = useState([]);
    const [SliderHover, setSliderHover] = useState('');
    // useEffect(() => {
    //     if ("geolocation" in navigator) {
    //         navigator.geolocation.getCurrentPosition(function (position) {
    //             setUserLocation({
    //                 latitude: position.coords.latitude,
    //                 longitude: position.coords.longitude,
    //             });
    //         });
    //     } else {
    //         console.log("Geolocation is not available in your browser.");
    //     }
    // }, []);
    // useEffect(() => {
    //     if (userLocation.latitude != null && userLocation.longitude != null) {
    //         axios({
    //             method: 'post',
    //             url: Get_Nearest_Outlet,
    //             headers: {
    //                 'Content-Type': 'application/x-www-form-urlencoded'
    //             },
    //             data: userLocation,
    //         }).then(response => {
    //             let nearestLocation = response.data.data.nearestLocation;
    //             if(!isEmptyObject(nearestLocation)){
    //                 if (localStorage.getItem('location') == null || localStorage.getItem('location') == undefined || isEmptyObject(localStorage.getItem('location'))) {
    //                     setNearestOutlet(nearestLocation);
    //                     if (!isEmptyObject(nearestLocation)) {
    //                         let outlet = [];
    //                         SliderData.map((item, index) => {
    //                             if (item.outletType == nearestLocation.outletType) {
    //                                 outlet.push(item);
    //                             }
    //                         });
    //                         // if (outlet.length > 0) {
    //                         // setSliderData(outlet);
    //                         // }
    //                     }
    //                 } else {
    //                     nearestLocation = JSON.parse(localStorage.getItem('location'));
    //                     setNearestOutlet(nearestLocation);
    //                     if (!isEmptyObject(nearestLocation)) {
    //                         let outlet = [];
    //                         SliderData.map((item, index) => {
    //                             if (item.outletType == nearestLocation.outletType) {
    //                                 outlet.push(item);
    //                             }
    //                         });
    //                         // if (outlet.length > 0) {
    //                         // setSliderData(outlet);
    //                         // }
    //                     }
    //                 }
    //             }
    //         }).catch(err => {
    //             console.log(err);
    //         })
    //     }
    // }, [userLocation])

    const options = {
        loop: true,
        dots: true,
        nav: false,
        autoplay: true,
        autoplayTimeout: 2000,
        navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
        responsive: {
            0: {
                items: 1,
                nav: false,
                loop: true
            },
            600: {
                items: 1,
                nav: false,
                loop: true
            },
            1000: {
                items: 1,
                nav: false,
                loop: true
            }
        }
    };

    useEffect(() => {
        Get_All_Restaurant_Images_Function();
    }, []);

    useEffect(() => {
        getSuperCategory();
        Get_Slider_Api_Function();
        Get_ContentData_Api_Function();
    }, []);
    function Get_Slider_Api_Function() {
        axios({
            method: 'post',
            url: Get_Slider_Api,
            data: {
                store: nearestOutlet.id
            },
        }).then(response => {
            // let nearestLocation = JSON.parse(localStorage.getItem('location'));
            // // setNearestOutlet(nearestLocation);
            // if (!isEmptyObject(nearestLocation)) {
            //     let outlet = [];
            //     response.data.data.map((item, index) => {
            //         if (item.outletType == nearestLocation.outletType) {
            //             outlet.push(item);
            //         }
            //     });
            //     // if (outlet.length > 0) {
            //         setSliderData(outlet);
            //     // }
            // } else {
            setSliderData(response.data.data);
            // }
        }).catch(err => {
            console.log(err)
        })
    }
    async function Get_All_Restaurant_Images_Function() {
        setIsLoad(true);
        await axios({
            method: 'get',
            url: Get_All_Restaurant_Images,
        }).then(response => {
            setMainSliderData(response.data.data);
            setIsLoad(false);
        }).catch(err => {
            console.log(err)
        })
    }
    function Get_ContentData_Api_Function() {
        axios({
            method: 'get',
            url: Get_ContentData_Api,
        }).then(response => {
            if (response.data.data[response.data.data.findIndex(x => x.key == 'special')]) {
                setSpecial(response.data.data[response.data.data.findIndex(x => x.key == 'special')]);
            }
            if (response.data.data[response.data.data.findIndex(x => x.key == 'order_online')]) {
                setOrderOnline(response.data.data[response.data.data.findIndex(x => x.key == 'order_online')]);
            }
            if (response.data.data[response.data.data.findIndex(x => x.key == 'top_pics')]) {
                setTopPics(response.data.data[response.data.data.findIndex(x => x.key == 'top_pics')]);
            }
            if (response.data.data[response.data.data.findIndex(x => x.key == 'Indo_Chinese_Express')]) {
                setIndo_Chinese_Express(response.data.data[response.data.data.findIndex(x => x.key == 'Indo_Chinese_Express')]);
            }
        }).catch(err => {
            console.log(err)
        })
    }
    function getSuperCategory() {
        axios({
            method: 'get',
            url: Get_SuperCategory_Data_Api,
        }).then(response => {
            setSuperCategory(response.data.data);
            // setIsLoad(true);
        }).catch(err => {
            console.log(err)
        })
    }
    return (
        <>
            <Header fileName={'homePage'} nearestOutletParams={nearestOutlet} setNearestOutletParams={setNearestOutlet} />
            {IsLoad == true ? <Loader /> : ''}
            <div className="home">
                <div className="container py-5">
                    <div className="heading">
                        <div className='d-flex justify-content-between'>
                            <h4>What's on your mind?</h4>
                            <div className='custom-slider-btn d-flex'>
                                <button id="prevButton" className="slider-btn me-2" onClick={() => document.getElementById('menu').scrollBy({ left: -200, behavior: 'smooth' })}>
                                    <span>
                                        <i className="bi bi-arrow-left"></i>
                                    </span>
                                </button>
                                <button id="forwardButton" className="slider-btn" onClick={() => document.getElementById('menu').scrollBy({ left: 200, behavior: 'smooth' })}>
                                    <span>
                                        <i className="bi bi-arrow-right"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="slider-body">
                            <div className="menu" id="menu">
                                <div className="menu--wrapper w-100">
                                    {SliderData.map((item, index) => {
                                        return (
                                            <div className="menu--item text-center" onMouseEnter={() => setSliderHover(item)} onMouseLeave={() => setSliderHover()} onClick={() => navigate(`/product/${item.superCategory_id}`)} key={index}>
                                                <div style={{ width: SliderHover == item ? '145px' : '125px', height: SliderHover == item ? '145px' : '125px' }}>
                                                    <div className='slider-image-div'>
                                                        <LazyLoadImage
                                                            key={index}
                                                            src={item.image}
                                                            placeholderSrc="/assets/images/default_img.jpeg"
                                                            alt={`Image`}
                                                            effect="opacity"
                                                            className="w-100"
                                                        />
                                                        {/* <img src={item.image} alt="Image" /> */}
                                                    </div>
                                                </div>
                                                <div className='text-dark mt-2' style={{ fontSize: '13px', fontWeight: 'bold' }}>
                                                    <p>{item.SuperCategory.superCategoryName}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* img Section */}
            <Swiper
                className='slider-sec custom-slider-sec position-relative"'
                modules={[Autoplay, Pagination]} // Include required modules
                loop={true} // Enable infinite loop
                autoplay={{
                    delay: 3000, // Set autoplay delay in ms
                    disableOnInteraction: false, // Continue autoplay after interaction
                }}
                pagination={{ clickable: true }} // Enable pagination dots
                spaceBetween={5} // Space between slides
                slidesPerView={1} // Number of slides visible at a time
            >
                { mainSliderData.map((item, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <div className="slider h-100">
                                <img src={item.image} className="w-100 h-100 object-fit-cover" alt="slider image" />
                            </div>
                        </SwiperSlide>
                    )
                }) }
            </Swiper>


            {/* <div className="container py-5"> */}

            {/* Seller Section */}
            <section className="seller-sec" style={{ padding: '40px 0' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <h6>Order Now</h6>
                            <div className="seller-box d-flex flex-wrap align-items-center justify-content-between">
                                <div className="d-flex align-items-center col">
                                    <img src="assets/images/delivery.png" className="px-4" />
                                    <h5>WokonFire <span>Delivery</span></h5>
                                </div>
                                <button className="submit-btn"><Link className="text-white" to={"/product"}>ORDER NOW</Link></button>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 mt-5 my-md-0">
                            <h6>Best Seller</h6>
                            <div className="seller-box d-flex flex-wrap align-items-center justify-content-between">
                                <div className="d-flex align-items-center col">
                                    <img src="assets/images/store.png" className="px-4" />
                                    <h5>WokonFire <span>Near me</span></h5>
                                </div>
                                <button className="submit-btn"><Link className="text-white" to={"/product"}>ORDER NOW</Link></button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="order-sec">
                <div className="container">
                    <div className="title">
                        <h4><strong>Why stay hungry when you can</strong><br />order from WokOnFire</h4>
                    </div>
                    <div className="row justify-content-around">
                        <div className="col-12 col-md-6"> 
                            <div className="order-box w-100 h-100">
                                <div className='h-100'>
                                    <LazyLoadImage
                                        src={topPics.image}
                                        placeholderSrc="/assets/images/default_img.jpeg"
                                        alt={`Image`}
                                        effect="opacity"
                                        className="w-100 h-100 object-fit-cover rounded-5"
                                    />
                                    {/* <img src={topPics.image} className="w-100 h-100" /> */}
                                </div>
                                <div className="txt-title d-flex flex-wrap align-items-center justify-content-between rounded-bottom-5">
                                    <div>
                                        <h6>{topPics.title}</h6>
                                        <p>{topPics.desc}</p>
                                    </div>
                                    <button><Link className="menu-btn d-inline-block" to={"/product"}>ORDER NOW</Link></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 mt-5 my-md-0">
                            <div className="order-box w-100 h-100">
                                <div className='h-100'>
                                    <LazyLoadImage
                                        src={special.image}
                                        placeholderSrc="/assets/images/default_img.jpeg"
                                        alt={`Image`}
                                        effect="opacity"
                                        className="w-100 h-100 object-fit-cover rounded-5"
                                    />
                                </div>
                                <div className="txt-title d-flex flex-wrap align-items-center justify-content-between rounded-bottom-5">
                                    <div>
                                        <h6>{special.title}</h6>
                                        <p>{special.desc}</p>
                                    </div>
                                    <button><Link className="menu-btn d-inline-block" to={"/product"}>ORDER NOW</Link></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="app-sec">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-6">
                            <div className="link-sec">
                                <h5>We are launching soon...</h5>
                                <p>For better experience</p>
                                <div className="row">
                                    <div className="col-12 col-sm-6 text-center">
                                        <a href="https://play.google.com/store/apps?hl=en_IN&pli=1" target="_blank" className='d-inline-block'>
                                            <img src="/assets/images/play.png" className="w-100" />
                                        </a>
                                    </div>
                                    <div className="col-12 col-sm-6 text-center mt-3 my-sm-0">
                                        <a href="https://www.apple.com/in/app-store/" target="_blank" className='d-inline-block'>
                                            <img src="/assets/images/app-store.png" className="w-100" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 mt-4 my-md-0">
                            <div className="img-box text-center">
                                <img src="/assets/images/mobile.png" className="w-100" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="online-order-sec">
                <div className="img-box h-100">
                    <LazyLoadImage
                        src={orderOnline.image}
                        placeholderSrc="/assets/images/default_img.jpeg"
                        alt={`Image`}
                        effect="opacity"
                        className="w-100 h-100 object-fit-cover"
                    />
                    {/* <img src={orderOnline.image} className="w-100" /> */}
                    <button><Link className="order-btn" to={"/product"}>ORDER ONLINE</Link></button>
                </div>
            </section>

            {/* <section className="menu-sec">
                    <div className="container">
                        <div className="title">
                            <h4>Discover Our Menu</h4>
                            <p>Embark on a culinary journey with our diverse menu, where traditional flavors meet modern twists to delight your palate with China's finest dishes.</p>
                            <img src={drop} />
                        </div>
                    </div>


                    <div className="overlay"></div>
                    {
                        typeof nearestOutlet.outletType != typeof undefined && nearestOutlet.outletType.toLowerCase().includes("express") ?
                            <div className="menu-box">
                                <div className="auto-container">
                                    <div className="row cus-flex clearfix">
                                        <div className="col-lg-6 col-md-6 col-sm-12 ps-0">
                                            <div className="inner-columns">
                                                <div className="pic">
                                                    <img src={Indo_Chinese_Express.image} />
                                                </div>
                                                <button onClick={() => navigate('/product')}><a className="order-btn">ORDER ONLINE</a></button>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="menu-detail ms-md-5">
                                                <h2>{Indo_Chinese_Express.title}</h2>
                                                <p>{Indo_Chinese_Express.desc}</p>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="menu-item me-md-5">
                                                            {
                                                                superCategory.length > 0 && superCategory.map((item, index) => {
                                                                    if (index <= 4) {
                                                                        return (
                                                                            <p key={index}>{item.superCategoryName}</p>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="menu-item me-md-5">
                                                            {
                                                                superCategory.length > 0 && superCategory.map((item, index) => {
                                                                    if (index > 4 && index <= 9) {
                                                                        return (
                                                                            <p key={index}>{item.superCategoryName}</p>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            <div className="menu-box">
                                <div className="auto-container">
                                    <div className="row cus-flex clearfix">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="menu-detail me-md-5">
                                                <h2>{Indo_Chinese.title}</h2>
                                                <p>{Indo_Chinese.desc}</p>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="menu-item me-md-5">
                                                            {
                                                                superCategory.length > 0 && superCategory.map((item, index) => {
                                                                    if (index <= 4) {
                                                                        return (
                                                                            <p key={index}>{item.superCategoryName}</p>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="menu-item me-md-5">
                                                            {
                                                                superCategory.length > 0 && superCategory.map((item, index) => {
                                                                    if (index > 4 && index <= 9) {
                                                                        return (
                                                                            <p key={index}>{item.superCategoryName}</p>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 ps-0">
                                            <div className="inner-column">
                                                <div className="pic">
                                                    <img src={Indo_Chinese.image} className='w-100' />
                                                </div>
                                                <button onClick={() => navigate('/product')}><a className="order-btn">ORDER ONLINE</a></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </section> */}
            <section className="brand-detail m-0" style={{ padding: '80px 0' }}>
                <div className="container">
                    <div className="row justify-content-start justify-content-md-around">
                        <div className="col-md-3">
                            <div className="brand-box mr-md-4">
                                <h1>12 <span>+</span></h1>
                                <h6>OUTLETS</h6>
                                <div className="line"></div>
                                <p>Surat | Vadodara | Ahemdabad | Anand</p>
                            </div>
                        </div>
                        <div className="col-md-3 mt-5 my-md-0">
                            <div className="brand-box mr-md-4">
                                <h1>20L <span>+</span></h1>
                                <h6>SATISFIED CUSTOMERS</h6>
                                <div className="line"></div>
                                <p>Joyful Epicureans: Where Palates Dance and Hearts Sing</p>
                            </div>
                        </div>
                        <div className="col-md-3 mt-5 my-md-0">
                            <div className="brand-box mr-md-4">
                                <h1>85 <span>%</span></h1>
                                <h6>RETAINING STAFF</h6>
                                <div className="line"></div>
                                <p>Harmony in the Kitchen: A Melody of Loyalty and Dedication</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* </div> */}
            <Footer />
        </>
    )
}

export default HomePage