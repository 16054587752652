import React, { useEffect, useRef, useState } from 'react'
import Header from '../UI/Header/Header'
import Footer from '../UI/Footer/Footer'
import { useNavigate } from 'react-router-dom'


const Disclaimer = () => {
    const navigate = useNavigate();
    const sectionRef = useRef();
    const [nearestOutlet, setNearestOutlet] = useState(JSON.parse(localStorage.getItem('location')) || {});

    useEffect(() => {
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    })

    return (
        <>
            <div ref={sectionRef}>
                <Header fileName={'disclaimer'} nearestOutletParams={nearestOutlet} setNearestOutletParams={setNearestOutlet} />
                <div className="disclaimer">
                    <div className="container">
                        <button onClick={() => navigate('/')} className="back">Back to Home</button>
                        <div className="title">
                            <h5>Disclaimer</h5>
                        </div>
                        <div className="copyright">
                            <h4>Disclaimer</h4>
                            <p>The information on this website is for informational purposes only and is not intended to be a substitute for professional advice. While we take reasonable care to ensure the accuracy of the information on this website, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability of the information, products, services, or graphics contained on this website for any purpose. You acknowledge that relying on any information on this website is entirely at your own risk.</p>
                        </div>
                        <div className="copyright">
                            <h4>Limitations of liability</h4>
                            <p>In no event will we be liable for any loss or damage, including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.</p>
                        </div>
                        <div className="copyright">
                            <h4>External links</h4>
                            <p>This website may contain links to other websites. We are not responsible for the content or privacy practices of these websites.</p>
                        </div>
                        <div className="copyright">
                            <h4>Copyright</h4>
                            <p>The content of this website is protected by copyright. All rights reserved.</p>
                        </div>
                        <div className="copyright">
                            <h4>Changes to this disclaimer</h4>
                            <p>We reserve the right to change this disclaimer at any time.  Your continued use of this website following any changes signifies your acceptance of those changes.</p>
                        </div>
                        <div className="copyright">
                            <h4>Contact Us</h4>
                            <p>If you have any questions about this disclaimer, please contact us at [your email address].</p>
                        </div>
                        <div className="copyright">
                            <h4>Additional considerations</h4>
                            <p>You may want to add a specific disclaimer about the recipes on your website, such as the fact that they should not be considered substitutes for professional medical advice.
                                <br />
                                You may also want to include a disclaimer about the use of images and other content on your website.</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Disclaimer