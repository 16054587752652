import React, { useEffect, useRef, useState } from 'react'
import Header from '../UI/Header/Header'
import Footer from '../UI/Footer/Footer'
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Virtual } from 'swiper/modules';

const About = () => {
    const navigate = useNavigate();
    const sectionRef = useRef();
    const [nearestOutlet, setNearestOutlet] = useState(JSON.parse(localStorage.getItem('location')) || {});

    useEffect(() => {
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    })

    return (
        <>
            <div ref={sectionRef}>
                <Header fileName={'about'} nearestOutletParams={nearestOutlet} setNearestOutletParams={setNearestOutlet} />
                <div className="about">
                    <div className="container">
                        <button className="back" onClick={() => navigate('/')}>Back to Home</button>
                        <div className="title">
                            <h5>About us</h5>
                        </div>
                    </div>
                    <div className="about-ceo">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="img-box me-md-5">
                                        <img src="/assets/images/about.png" className="w-100" />
                                    </div>
                                </div>
                                <div className="col-md-7 mt-5 my-md-0">
                                    <div className="about-txt about-title">
                                        <h6>About Us</h6>
                                        <h4>Our Journey to Sweet Success. A Tale of Flour and Sugar</h4>
                                        <p>Red and black fiery colors and heady aroma of Chinese cuisine wafting in a tightly packed but tastefully done room – that description is the only befitting description of the city’s most loved authentic (well almost) Chinese (Cantonese, Hunan et al.) restaurant Wok on Fire. Establishing its base in the city of Surat, Wok on Fire has walked and gone a long way and has never looked back since.

                                            Sohil decodes the secret behind why Wok on Fire stands out of the league. He describes “You wouldn’t find a Vegetarian Indo-Chinese restaurant chain equivalent to Wok on Fire anywhere in India.” This is what their USP and strength is. The concept of this QSR, the outlet design, the menu, quality and quantity of dishes and a careful melange of spices that suit an Indian’s Chinese taste buds all add to the paraphernalia required to set up this high-profile franchise. You can probably spot their signature take-away RED boxes in every household that loves Chinese food</p>
                                        <ul className='overflow-hidden'>
                                            <li className="ceo">
                                                <h6>Sohil Panjwani</h6>
                                                <p>Founder & CEO</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="visit-sec">
                        <div className="container">
                            <div className="about-title">
                                <h6>Why visit us</h6>
                                <h4>We Provide Delicious Food & Best Facilities</h4>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="box">
                                        <div className="img-box">
                                            <img src="/assets/images/visit-01.png" />
                                        </div>
                                        <h4>Quality</h4>
                                        <p>At Wokonfire, quality is our top priority. We believe that great food begins with great ingredients, so we source only the finest and freshest produce for our dishes. From farm-fresh vegetables to premium cuts of meat, we spare no effort to ensure that every ingredient meets our rigorous standards of quality and freshness.</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="box">
                                        <div className="img-box">
                                            <img src="/assets/images/visit-02.png" />
                                        </div>
                                        <h4>Special Menu</h4>
                                        <p>we take pride in offering a special menu that showcases the best of Indo Chinese cuisine. From classic favorites to modern interpretations, our menu is designed to delight and inspire, offering something for everyone to enjoy. our special menu is sure to satisfy your cravings and leave you craving more</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="box">
                                        <div className="img-box">
                                            <img src="/assets/images/visit-03.png" />
                                        </div>
                                        <h4>Best Dining</h4>
                                        <p>we believe that dining isn't just about eating; it's about creating memories and sharing moments with loved ones. That's why we go above and beyond to provide the best dining experience possible, from the moment you walk through our doors to the last sip of your drink.
                                            Change 25+ to 11+ Outlet
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="franchise-detail">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3 col-6">
                                    <div className="box">
                                        <h2>18+</h2>
                                        <p>Years Experience</p>
                                    </div>
                                    <span></span>
                                </div>
                                <div className="col-md-3 col-6">
                                    <div className="box">
                                        <h2>10+</h2>
                                        <p>Master Chefs</p>
                                    </div>
                                    <span></span>
                                </div>
                                <div className="col-md-3 col-6 mt-4 my-md-0">
                                    <div className="box">
                                        <h2>200+</h2>
                                        <p>Daily Visitors</p>
                                    </div>
                                </div>
                                <div className="col-md-3 col-6 mt-4 my-md-0">
                                    <div className="box">
                                        <h2>25+</h2>
                                        <p>Outlets</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="mission-sec">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="topic">
                                        <h2>Our <span>Mission</span></h2>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="sub-detail">
                                        <p>omato Limited (Formerly known as Zomato Private Limited and Zomato Media Private Limited) and/or its affiliates ("Zomato," the "Company," "we," "us," and "our,") respect your privacy and is committed to protecting it through its compliance with its privacy policies. This policy describes:</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mission-sec">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="topic">
                                        <h2>Our <span>Vision</span></h2>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="sub-detail">
                                        <p>omato Limited (Formerly known as Zomato Private Limited and Zomato Media Private Limited) and/or its affiliates ("Zomato," the "Company," "we," "us," and "our,") respect your privacy and is committed to protecting it through its compliance with its privacy policies. This policy describes:</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="mission-sec">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="topic">
                                        <h2>Our Mission:</h2>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="sub-detail">
                                        <p>
                                            At WokOnFire, our mission is simple: to delight our customers with exceptional food and service that exceeds their expectations. We believe that great food has the power to bring people together, and we're passionate about creating memorable dining experiences that leave a lasting impression.
                                            <br />
                                            From the moment you walk through our doors, we want you to feel welcomed and valued. Our friendly staff is dedicated to providing attentive service and personalized recommendations to ensure that your dining experience is nothing short of perfect.
                                            <br />
                                            But our mission goes beyond just serving great food; we're also committed to making a positive impact in our community. Whether it's sourcing ingredients from local farmers or participating in charitable events, we believe in giving back and supporting the people and places that make our restaurant possible.
                                            <br />
                                            Join us at Wokonfire and experience our mission in action. Whether you're dining in or taking out, we promise to provide an unforgettable experience that keeps you coming back for more.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mission-sec">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="topic">
                                        <h2>Our Vision:</h2>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="sub-detail">
                                        <p>
                                            At Wokonfire, our vision is to be the premier destination for Indo Chinese cuisine, known for our exceptional food, warm hospitality, and commitment to quality. We aspire to create a dining experience that transcends borders and celebrates the rich tapestry of flavors and cultures that make up our menu.
                                            <br />
                                            We envision a restaurant where every guest feels welcomed and valued, where every dish is a masterpiece, and where every meal is an opportunity to create cherished memories with loved ones. We strive to be a place where people come together to celebrate life's moments, big and small, and to savor the simple joys of great food and great company.
                                            <br />
                                            But our vision extends beyond just our restaurant walls; we also aim to be a positive force in our community, supporting local farmers, artisans, and charities, and making a meaningful difference in the lives of those around us.
                                            <br />
                                            Join us at Wokonfire and be a part of our vision for a world where good food brings people together and creates lasting connections. Together, we can make every meal a celebration of flavor, culture, and community.

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="testimonial-sec">
                        <div className="container">
                            <div className="about-title">
                                <h6>#Testimoniols</h6>
                                <h4>Customer Voices <span> Here what they says</span></h4>
                            </div>
                            <Swiper
                                modules={[Autoplay, Virtual]} // Include required modules
                                loop={true} // Enable infinite loop
                                virtual
                                autoplay={{
                                    delay: 3000, // Set autoplay delay in ms
                                    disableOnInteraction: false, // Continue autoplay after interaction
                                }}
                                spaceBetween={30} // Space between slides
                                slidesPerView={1} // Number of slides visible at a time
                            >
                                <SwiperSlide>
                                    <div className="item client" key="1">
                                        <img src="/assets/images/coma.png" />
                                        <h4>Gurav Lad</h4>
                                        {/* <h6>DEVELOPER</h6> */}
                                        <p>We went on Wednesday with 50% off. We tried Burmese soup, paneer chilly, tempura bao, Dragon pompom.Dragon pompom is too oily. But taste buds it is good </p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="item client" key="2">
                                        <img src="/assets/images/coma.png" />
                                        <h4>Disha thakkar</h4>
                                        {/* <h6>DEVELOPER</h6> */}
                                        <p>Recently dined at Wok on fire and the food was delicious. The menu has a wide range of authentic Indo-Chinese flavours. We ordered veg lollipop and a sizzler that was finger licking good. The service is on time too.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="item client" key="3">
                                        <img src="/assets/images/coma.png" />
                                        <h4>Jainil Shah</h4>
                                        {/* <h6>DEVELOPER</h6> */}
                                        <p>Smoke was coming out in the seating area from the kitchen.
                                            The food experience was really good
                                            Recomendetion:
                                            Manchurian, Singapore rice, Shanghai noodles, burnt garlic soup.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="item client" key="4">
                                        <img src="/assets/images/coma.png" />
                                        <h4>Neel Patel</h4>
                                        {/* <h6>DEVELOPER</h6> */}
                                        <p>Had a best Manchurian with Shanghai flavour and Paneer chilly. Taste was so good and service is on top even staff is too familiar and humble.</p>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                            {/* <div className="testimonial-carousel owl-carousel owl-theme"> */}
                            {/* <div className="testimonial-carousel1 owl-theme">
                                <div className="client">
                                    <img src="/assets/images/coma.png" />
                                    <h4>Kinjal Parmar</h4>
                                    <h6>DEVELOPER</h6>
                                    <p>At vero eos et accusamus etodio dignissimos ducimus praesentium voluptatum corrupti quos dolores quas molestias excepturi sint occaecati cupiditate provident qui officia deserunt mollitia animi.ucimus praesentium </p>
                                </div>
                                <div className="client">
                                    <img src="/assets/images/coma.png" />
                                    <h4>Kinjal Parmar</h4>
                                    <h6>DEVELOPER</h6>
                                    <p>At vero eos et accusamus etodio dignissimos ducimus praesentium voluptatum corrupti quos dolores quas molestias excepturi sint occaecati cupiditate provident qui officia deserunt mollitia animi.ucimus praesentium </p>
                                </div>
                                <div className="client">
                                    <img src="/assets/images/coma.png" />
                                    <h4>Kinjal Parmar</h4>
                                    <h6>DEVELOPER</h6>
                                    <p>At vero eos et accusamus etodio dignissimos ducimus praesentium voluptatum corrupti quos dolores quas molestias excepturi sint occaecati cupiditate provident qui officia deserunt mollitia animi.ucimus praesentium </p>
                                </div>
                            </div> */}

                        </div>
                    </div>
                </div>
                <div className="container py-4">
                    <div className="title">
                        <div className="bottom-sub-detail">
                            <h4>Contact Us</h4>
                            <p>MR MISCHIEF FOOD CO.</p>
                            <p>Register Address</p>
                            <p>RAJMAHAL COMPLEX, 83 A PLOT NO UG 115 116, OPP</p>
                            <p>RATNAVILASH, VESU ROAD, VESU, SURAT, GUJARAT, 395007</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default About