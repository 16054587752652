import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import $, { isEmptyObject } from 'jquery';
import {
    Get_Cart_Api,
    Login_User_Api,
    Get_Login_User_Api,
    User_Verify_Api,
    Log_Out_Api,
    Register_User_Api,
    Change_Delivery_Type_Api,
    Add_Money_Wallet_Api,
    Get_AllCityOutlet_Api,
    Update_PickUp_Outlet,
    Outlet_Search_Api,
    Get_Nearest_Outlet
} from '../../../api';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../Loader';
import 'react-toastify/dist/ReactToastify.css';

const Header = ({ fileName, nearestOutletParams, setNearestOutletParams, setSearchTextParams, cartCount }) => {
    const navigate = useNavigate();
    const loginFormRef = useRef(null);
    const otpFormRef = useRef(null);
    const signUpFormRef = useRef(null);
    const addWalletRef = useRef(null);
    const pickUpLocationFormRef = useRef(null);
    const formRef = useRef(null);

    const [localStorageLocation, setLocalStorageLocation] = useState(() => JSON.parse(localStorage.getItem('location')));
    const [userLocation, setUserLocation] = useState({ latitude: null, longitude: null });
    const [nearestOutlet, setNearestOutlet] = useState(localStorageLocation || nearestOutletParams);
    const [userData, setUserData] = useState({});
    const [locationSuggestionData, setLocationSuggestionData] = useState([]);
    const [city, setCity] = useState([]);
    const [outlets, setOutlets] = useState({ selected: [], all: [] });
    const [userName, setUserName] = useState(null);
    const [mobileNumber, setMobileNumber] = useState('');
    const [area, setArea] = useState({});
    const [userNumber, setUserNumber] = useState(null);
    const [cartCountState, setCartCount] = useState(0);
    const [deliveryType, setDeliveryType] = useState(localStorage.getItem('orderType') || "delivery");
    const [recentLocation, setRecentLocation] = useState(() => JSON.parse(localStorage.getItem('recent_location')) || []);
    const token = localStorage.getItem('authToken');
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [locationLoading, setLocationLoading] = useState(false);
    const [secondLocationLoading, setSecondLocationLoading] = useState(() => localStorage.getItem('locationLoading') === 'true');
    const [loginLoading, setLoginLoading] = useState(false);
    const [signUpData, setSignUpData] = useState({
        first_name: '',
        mobile_number: '',
        email: ''
    })

    useEffect(() => {
        const storedLocation = JSON.parse(localStorage.getItem('location'));

        if (!storedLocation || Object.keys(storedLocation).length === 0) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        const userLocation = { latitude, longitude };
                        setUserLocation(userLocation);
                        localStorage.setItem('letlong', JSON.stringify(userLocation));
                        setLocationLoading(true);
                        setSecondLocationLoading(false);
                    },
                    (error) => {
                        setLocationLoading(false);
                        setShowModal(true);
                    }
                );
            } else {
                setShowModal(true);
                setLocationLoading(false);
            }
        } else {
            setUserLocation(storedLocation);
        }
    }, []);

    useEffect(() => {
        if (showModal && !locationLoading) {
            const myModal = document.getElementsByClassName('storeLocation');
            if (myModal.length > 0) {
                myModal[0].click();
            }
        }
    }, [showModal, locationLoading]);

    useEffect(() => {
        if (!secondLocationLoading) {
            localStorage.setItem('locationLoading', 'true');
        }
    }, [secondLocationLoading]);

    useEffect(() => {
        if (!isEmptyObject(nearestOutlet)) {
            const localStorageLocationData = {
                city: nearestOutlet.city,
                id: nearestOutlet.id,
                name: nearestOutlet.masterName || nearestOutlet.name,
                outletType: nearestOutlet.outletType,
                masterName: nearestOutlet.masterName,
                delivery: nearestOutlet.delivery,
                pickup: nearestOutlet.pickup,
            };
            localStorage.setItem('location', JSON.stringify(localStorageLocationData));
            localStorage.setItem('locationLoading', 'true');
        }
    }, [nearestOutlet]);

    useEffect(() => {
        if (userLocation.latitude != null && userLocation.longitude != null) {
            axios.post(Get_Nearest_Outlet, userLocation, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(response => {
                let nearestLocation = response.data.data.nearestLocation;
                if (nearestLocation && Object.keys(nearestLocation).length > 0) {
                    if (!localStorage.getItem('location') || Object.keys(JSON.parse(localStorage.getItem('location'))).length === 0) {
                        setNearestOutlet(nearestLocation);
                        if (setNearestOutletParams) {
                            setNearestOutletParams(nearestLocation);
                        }
                    }
                }
            }).catch(err => {
                console.error(err);
            })
        }
    }, [userLocation]);

    const cartCountFunction = () => {
        if (token) {
            axios.get(Get_Cart_Api, {
                headers: { 'Authorization': `Bearer ${token}` }
            }).then((response) => {
                setCartCount(response.data.data.cart_data.length || 0);
            }).catch((error) => {
                console.error(error);
            }).finally(() => {
            });
        }
    };

    const logOutFunction = async () => {
        await axios.get(Log_Out_Api, {
            headers: { 'Authorization': `Bearer ${token}` }
        }).then((response) => {
            toast.success(response.data.msg);
            localStorage.removeItem('authToken');
            setTimeout(() => {
                window.location.reload();
            }, 500);
        }).catch((error) => {
            console.error(error);
        })
        setCartCount(0);
    };

    useEffect(() => {
        $(loginFormRef.current).validate({
            rules: {
                mobile_number: {
                    required: true,
                    minlength: 10,
                    maxlength: 10,
                },
            },
            messages: {
                mobile_number: {
                    required: 'Please enter a mobile number',
                    minlength: 'Please enter valid mobile number',
                    maxlength: 'Please enter valid mobile number',
                },
            },
            errorPlacement: function (error, element) {
                error.insertAfter($(element).closest(".newerror"));
            },
            submitHandler: (form) => {
                setLoginLoading(true);
                let n_form = $(form).serialize();
                axios.post(Login_User_Api, n_form, {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }).then(async (response) => {
                    setLoginLoading(false);
                    if (!response.data.error) {
                        window.$('#loginModal').offcanvas('hide');
                        window.$('#otpModal').offcanvas('show');
                        setUserNumber(response.data.user.mobile_number);
                        toast.success(response.data.msg);
                        localStorage.setItem('otpToken', response.data.token);

                        const apiUrl = `https://api.smartping.ai/fe/api/v1/send?username=misctrmpg.trans&password=pxhDs&unicode=false&from=WKONFR&to=${response.data.user.mobile_number}&dltPrincipalEntityId=1401432670000029918&dltContentId=1707170711006359607&text=Your%20OTP%20for%20Wok%20On%20Fire%20Login%20is%20${response.data.user.otp}.%20OTP%20is%20confidential%3B%20please%20enter%20it%20yourself%20%26%20do%20not%20disclose%20it%20to%20anyone.`;

                        await axios.get(apiUrl)
                            .then(response => {
                                console.log('SMS sent successfully:', response.data);
                            })
                            .catch(error => {
                                console.error('Error sending SMS:', error);
                            });
                    } else {
                        toast.error(response.data.msg);
                    }
                }).catch((error) => {
                    console.error(error);
                    setLoginLoading(false);
                });
            },
        });

        if (token) {
            cartCountFunction();
        }
    }, [token]);

    useEffect(() => {
        $.validator.addMethod("emailValidate", function (value, element) {
            return /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value);
        }, "Please enter a valid email address.");
        $(signUpFormRef.current).validate({
            rules: {
                first_name: { required: true },
                mobile_number: {
                    required: true,
                    minlength: 10,
                    maxlength: 10,
                },
                email: { required: true, emailValidate: true }
            },
            messages: {
                first_name: { required: 'Please enter a Name' },
                mobile_number: {
                    required: 'Please enter a mobile number',
                    minlength: 'Please enter 10 digit mobile number',
                    maxlength: 'Please enter 10 digit mobile number',
                },
                email: { required: 'Please enter Email Address' }
            },
            errorPlacement: function (error, element) {
                error.insertAfter($(element).closest(".newerror"));
            },
            submitHandler: (form) => {
                setLoginLoading(true);
                console.log(signUpData);
                let n_form = $(form).serialize();
                const params = new URLSearchParams(n_form);
                const formMobileNumber = params.get('mobile_number');
                axios.post(Register_User_Api, n_form, {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }).then((response) => {
                    if (!response.data.error) {
                        setMobileNumber(formMobileNumber);
                        toast.success(response.data.msg);
                        window.$('#signupModal').offcanvas('hide');
                        window.$('#loginModal').offcanvas('show');
                    } else {
                        toast.error(response.data.msg);
                    }
                    setLoginLoading(false);
                }).catch((error) => {
                    console.error(error);
                    setLoginLoading(false);
                })
            },
        });
    }, []);

    useEffect(() => {
        if (token) {
            setLoading(true);
            axios.get(Get_Login_User_Api, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${token}`
                }
            }).then((response) => {
                if (!response.data.error) {
                    setUserData(response.data.data);
                    setDeliveryType(response.data.data.delivery_type);
                    setNearestOutletParams(response.data.data.deliveryOutletStore);
                    setNearestOutlet(response.data.data.deliveryOutletStore);
                    localStorage.setItem("orderType", response.data.data.delivery_type);
                    setUserNumber(response.data.data.mobile_number);
                    setUserName(response.data.data.first_name ?
                        `${response.data.data.first_name} ${response.data.data.last_name || ''}` :
                        response.data.data.last_name || 'User'
                    );
                    setArea({
                        _id: response.data.data.deliveryOutletStore._id || '',
                        name: response.data.data.deliveryOutletStore.name || '',
                        city: response.data.data.deliveryOutletStore.city || '',
                        outletType: response.data.data.deliveryOutletStore.outletType || '',
                    });
                }
                setLoading(false);
            }).catch((error) => {
                console.error(error);
            })
        }
    }, [token]);

    useEffect(() => {
        if (token && !isEmptyObject(nearestOutlet)) {
            axios.post(Update_PickUp_Outlet, { area: nearestOutlet.id, city: nearestOutlet.city }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${token}`
                }
            }).then((response) => {
                setUserData(response.data.data);
            }).catch((error) => {
                console.error(error);
            })
        }
    }, [token, nearestOutlet]);

    $(otpFormRef.current).validate({
        rules: {},
        messages: {},
        errorPlacement: function (error, element) {
            error.insertAfter($(element).closest(".newerror"));
        },
        submitHandler: (form) => {
            let n_form = $(form).serialize();
            let OTP_DATA = document.getElementsByClassName('otp-get-data');
            let Send_Input_Otp = '';
            let check_Null = false;
            for (const key of OTP_DATA) {
                if (key.value) {
                    Send_Input_Otp += key.value;
                } else {
                    check_Null = true;
                }
            }
            if (!check_Null) {
                setLoginLoading(true);
                axios.post(User_Verify_Api, { otp: Send_Input_Otp }, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${localStorage.getItem('otpToken')}`
                    }
                }).then((response) => {
                    setLoginLoading(false);
                    if (!response.data.error) {
                        toast.success(response.data.msg);
                        localStorage.removeItem('otpToken');
                        localStorage.setItem('authToken', response.data.token);
                        setTimeout(() => {
                            window.location.reload();
                        }, 500);
                    } else {
                        toast.error(response.data.msg);
                    }
                }).catch((error) => {
                    console.error(error);
                });
            } else {
                toast.error("Please enter 4 digit otp");
            }
        }
    });

    $(addWalletRef.current).validate({
        rules: {
            amount: { required: true }
        },
        messages: {
            amount: { required: 'please enter amount' }
        },
        errorPlacement: function (error, element) {
            error.insertAfter($(element).closest(".newerror"));
        },
        submitHandler: (form) => {
            let n_form = $(form).serialize();
            axios.post(Add_Money_Wallet_Api, n_form, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${token}`
                }
            }).then((response) => {
                if (response.data.encRequest) {
                    $('#encRequest').val(response.data.encRequest);
                    $('#access_code').val(response.data.accessCode);
                    $(formRef.current).submit();
                }
            }).catch((error) => {
                console.error(error);
            });
        }
    });

    $(pickUpLocationFormRef.current).validate({
        rules: {
            pickUp: { required: true },
            city: { required: true },
            area: { required: true }
        },
        messages: {
            pickUp: { required: 'please select delivery type' },
            city: { required: 'please select city' },
            area: { required: 'please select area' }
        },
        errorPlacement: function (error, element) {
            error.insertAfter($(element).closest(".newerror"));
        },
        submitHandler: (form) => {
            if (token) {
                let n_form = $(form).serialize();
                let n_form_new = $(form).serializeArray();
                localStorage.setItem('location', JSON.stringify({
                    city: n_form_new[0].value,
                    id: n_form_new[1].value,
                    name: n_form_new[2].value,
                    outletType: n_form_new[3].value,
                    masterName: n_form_new[4].value,
                    delivery: n_form_new[5]?.value || 'Enabled',
                    pickup: n_form_new[6]?.value || 'Enabled',
                }));
                n_form += `&pickUp=1`;
                axios.post(Update_PickUp_Outlet, n_form, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${token}`
                    }
                }).then((response) => {
                    setNearestOutlet({
                        city: n_form_new[0].value,
                        id: n_form_new[1].value,
                        name: n_form_new[2].value,
                        outletType: n_form_new[3].value,
                        masterName: n_form_new[4].value,
                        delivery: n_form_new[5]?.value || 'Enabled',
                        pickup: n_form_new[6]?.value || 'Enabled',
                    });
                    setNearestOutletParams({
                        city: n_form_new[0].value,
                        id: n_form_new[1].value,
                        name: n_form_new[2].value,
                        outletType: n_form_new[3].value,
                        masterName: n_form_new[4].value,
                        delivery: n_form_new[5]?.value || 'Enabled',
                        pickup: n_form_new[6]?.value || 'Enabled',
                    });
                    toast.success(response.data.msg);
                    setUserData(response.data.data);
                    if (fileName === 'product') {
                        window.location.href = "/product";
                    } else {
                        window.location.reload();
                    }
                }).catch((error) => {
                    console.error(error);
                })
            } else {
                let n_form = $(form).serializeArray();
                localStorage.setItem('location', JSON.stringify({
                    city: n_form[0].value,
                    id: n_form[1].value,
                    name: n_form[2].value,
                    outletType: n_form[3].value,
                    masterName: n_form[4].value,
                    delivery: n_form[5]?.value || 'Enabled',
                    pickup: n_form[6]?.value || 'Enabled',
                }));
                setNearestOutlet({
                    city: n_form[0].value,
                    id: n_form[1].value,
                    name: n_form[2].value,
                    outletType: n_form[3].value,
                    masterName: n_form[4].value,
                    delivery: n_form[5]?.value || 'Enabled',
                    pickup: n_form[6]?.value || 'Enabled',
                });
                setNearestOutletParams({
                    city: n_form[0].value,
                    id: n_form[1].value,
                    name: n_form[2].value,
                    outletType: n_form[3].value,
                    masterName: n_form[4].value,
                    delivery: n_form[5]?.value || 'Enabled',
                    pickup: n_form[6]?.value || 'Enabled',
                });
                toast.success('Location selected');
                if (fileName === 'product') {
                    window.location.href = "/product";
                } else {
                    window.location.reload();
                }
            }
        }
    });

    const resendOtp = () => {
        axios.post(Login_User_Api, { mobile_number: mobileNumber }, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(async (response) => {
            if (!response.data.error) {
                toast.success(response.data.msg);
                const apiUrl = `https://api.smartping.ai/fe/api/v1/send?username=misctrmpg.trans&password=pxhDs&unicode=false&from=WKONFR&to=${response.data.user.mobile_number}&dltPrincipalEntityId=1401432670000029918&dltContentId=1707170711006359607&text=Your%20OTP%20for%20Wok%20On%20Fire%20Login%20is%20${response.data.user.otp}.%20OTP%20is%20confidential%3B%20please%20enter%20it%20yourself%20%26%20do%20not%20disclose%20it%20to%20anyone.`;
                await axios.get(apiUrl)
                    .then(response => {
                        console.log('SMS sent successfully:', response.data);
                    })
                    .catch(error => {
                        console.error('Error sending SMS:', error);
                    });
            } else {
                toast.error(response.data.msg);
            }
        }).catch((error) => {
            console.error(error);
        });
    };

    const limitHandleChange = (event) => {
        const value = event.target.value;
        const onlyDigits = /^\d*$/;
        if (onlyDigits.test(value) && value.length <= 10) {
            setMobileNumber(value);
        }
    };

    const mobileNumberLimit = (event) => {
        const value = event.target.value;
        const onlyDigits = /^\d*$/;
        if (onlyDigits.test(value) && value.length <= 10) {
            setSignUpData({...signUpData, mobile_number: value});
        }
    }

    const deliveryTypeHandleChange = (val) => {
        setDeliveryType(val);
        localStorage.setItem('orderType', val);
        if (token) {
            axios.post(Change_Delivery_Type_Api, { type: val }, {
                headers: { 'Authorization': `Bearer ${token}` }
            }).then((response) => {
            }).catch((error) => {
                console.error(error);
            });
        }
    };

    useEffect(() => {
        axios.get(Get_AllCityOutlet_Api)
            .then((response) => {
                if (!response.data.error) {
                    let allStoreData = response.data.data.StoreData;
                    allStoreData = allStoreData.filter(element => element.pickup === 'Enabled');
                    setCity(response.data.data.cityData);
                    setOutlets({ ...outlets, all: allStoreData });
                    if (userData.city) {
                        let cityOutlet = allStoreData.filter(x => x.city.toLowerCase() === userData.city.toLowerCase());
                        setOutlets({ ...outlets, selected: cityOutlet });
                        let index = cityOutlet.findIndex((x) => x._id === userData.deliveryOutlet);
                        setArea(cityOutlet[index]);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            }).finally(() => {
                setLocationLoading(false);
            });
    }, [userData]);

    $('.otp-digit-group').find('input').each(function () {
        $(this).attr('maxlength', 1);
        $(this).on('keyup', function (e) {
            var parent = $($(this).parent());
            if (e.keyCode === 8 || e.keyCode === 37) {
                var prev = parent.find('input#' + $(this).data('previous'));
                $(this).attr('type', 'text');
                if (prev.length) {
                    $(prev).select();
                }
            } else if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 39) {
                var next = parent.find('input#' + $(this).data('next'));
                $(this).attr('type', 'password');
                if (next.length) {
                    $(next).select();
                } else {
                    if (parent.data('autosubmit')) {
                        parent.submit();
                    }
                }
            }
        });
    });

    const setOutletByCity = (city) => {
        if (city === '') {
            setOutlets({ ...outlets, selected: [] });
            setArea({});
            setUserData({ ...userData, pickupOutlet: '', city: '' });
        } else {
            let cityOutlet = outlets.all.filter(x => x.city.toLowerCase() === city);
            setOutlets({ ...outlets, selected: cityOutlet });
            setArea(cityOutlet[0]);
            setUserData({ ...userData, pickupOutlet: cityOutlet[0]._id, city: city });
        }
    };

    const deliveryOutletSearch = (e) => {
        e.preventDefault();
        let search = e.target.value;
        if (search) {
            setLocationLoading(true);
            axios.post(Outlet_Search_Api, { search: search }, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(async (response) => {
                if (!response.data.error) {
                    let result = response.data.data;
                    result = result.filter(element => element.delivery === 'Enabled');
                    for (let element of result) {
                        element.masterName = element.masterName || element.name;
                    }
                    setLocationSuggestionData(result);
                } else {
                    toast.error(response.data.msg);
                }
            }).catch((error) => {
                console.error(error);
            }).finally(() => {
                setLocationLoading(false);
            });
        } else {
            toast.error('Please enter area / city');
        }
    };

    const deleteLocalStorageSearch = (id) => {
        let data = JSON.parse(localStorage.getItem('recent_location'));
        data = data.filter(x => x.id !== id);
        localStorage.setItem('recent_location', JSON.stringify(data));
        setRecentLocation(data);
    };

    const changeDeliveryOutlet = (item) => {
        setNearestOutlet({
            name: item.name,
            city: item.city,
            id: item._id,
            outletType: item.outletType,
            masterName: item.masterName,
            delivery: item.delivery,
            pickup: item.pickup,
        });
        setNearestOutletParams({
            name: item.name,
            city: item.city,
            id: item._id,
            outletType: item.outletType,
            masterName: item.masterName,
            delivery: item.delivery,
            pickup: item.pickup,
        });
        localStorage.setItem('location', JSON.stringify({
            name: item.name,
            city: item.city,
            id: item._id,
            outletType: item.outletType,
            masterName: item.masterName,
            delivery: item.delivery,
            pickup: item.pickup,
        }));

        toast.success('Location selected');

        if (token) {
            axios.post(Update_PickUp_Outlet, {
                area: item.id || item._id,
                city: item.city,
                pickUp: 0
            }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${token}`
                }
            }).then((response) => {
                setUserData(response.data.data);
            }).catch((error) => {
                console.error(error);
            }).finally(() => {
            });
        }

        let latestData = {
            masterName: item.masterName,
            name: item.name || item.masterName,
            city: item.city,
            id: item._id,
            outletType: item.outletType
        };
        if (recentLocation.findIndex(x => x.name === latestData.name) < 0) {
            setRecentLocation([...recentLocation, latestData]);
            localStorage.setItem('recent_location', JSON.stringify([...recentLocation, latestData]));
        } else {
            setRecentLocation([latestData]);
            localStorage.setItem('recent_location', JSON.stringify([latestData]));
        }
        if (fileName === 'product') {
            window.location.href = "/product";
        } else {
            window.location.reload();
        }
    };

    const renderOptions = () => {
        const data = outlets.selected.length > 0 ? outlets.selected : outlets.all;
        return data.map((item, index) => (
            <option key={index} value={item._id}>
                {item.masterName || item.name}
            </option>
        ));
    };

    return (
        <>
            {loading ? <Loader /> :
                <>
                    <ToastContainer position='top-right' autoClose={3000} />
                    <form id="nonseamless" className='d-none' method="post" name="redirect" ref={formRef} action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction">
                        <input id="encRequest" name="encRequest" readOnly /><input name="access_code" id="access_code" readOnly />
                    </form>
                    {/* {(fileName === 'homePage') ? */}
                    <>
                        <header id="header" className="main-header">
                            <div className="container">
                                <div className="menu-container">
                                    <nav className="navbar navbar-expand-lg navbar-light">
                                        <div className="container">
                                            <a className="navbar-brand" href="/">
                                                <img src={'/assets/images/logo.png'} alt="Logo" />
                                            </a>
                                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample05" aria-controls="navbarsExample05" aria-expanded="false" aria-label="Toggle navigation">
                                                <span className="navbar-toggler-icon"></span>
                                            </button>
                                            <div className='outletSelect'>
                                                <div className="radio-btn mb-3">

                                                    { nearestOutlet.delivery == 'Enabled' || typeof nearestOutlet.delivery == typeof undefined ? <> <input type="radio" id="orderDelivery" name="productDelivery" onChange={() => deliveryTypeHandleChange('delivery')} checked={deliveryType === 'delivery'} />
                                                    <label htmlFor="orderDelivery">Delivery</label> </> : '' }

                                                    { nearestOutlet.pickup == 'Enabled' || typeof nearestOutlet.pickup == typeof undefined ? <> <input type="radio" id="orderPickUp" name="productDelivery" onChange={() => deliveryTypeHandleChange('take_out')} checked={deliveryType === 'take_out'} />
                                                    <label htmlFor="orderPickUp">Take Away</label> </> : '' }
                                                    
                                                </div>
                                                <div className="add justify-content-between storeLocation" data-bs-target={`#${deliveryType === 'delivery' ? 'locationModal1' : 'locationModal'}`} data-bs-toggle="offcanvas" style={{ cursor: "pointer" }}>
                                                    <img src="/assets/images/location.png" alt="Location" />
                                                    <div className="d-flex align-items-center">
                                                        <p> {!nearestOutlet ? 'Moti Begumwadi, Nanpura, Surat' : nearestOutlet.name} </p>
                                                    </div>
                                                    <img src="/assets/images/edit.png" alt="Edit" />
                                                </div>

                                            </div>
                                            <div className="collapse navbar-collapse" id="navbarsExample05">
                                                <ul className="navbar-nav ms-auto pl-0 overflow-hidden">
                                                    <div className="d-flex align-items-center me-3 mt-3 my-lg-0">
                                                        <img src="/assets/images/n-call.png" alt="Call" />
                                                        <div className="call-detail ms-2">
                                                            <p>Call us at</p>
                                                            <h6><Link to={"tel:7203066666"} className='text-black'>7203066666</Link></h6>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mt-3 my-lg-0">
                                                        { fileName !== 'homePage' ? <li className="nav-item"><Link to={"/product"} className="nav-link"><img src="/assets/images/n-menu.png" alt="Menu" /><br /> Menu</Link></li> : '' }
                                                        
                                                        <li className="nav-item"><Link to={"/offer"} className="nav-link"><img src="/assets/images/discount.png" alt="Offer" /><br /> Offer</Link></li>

                                                        <li className="nav-item"><Link to={""} className="nav-link"><img src="/assets/images/n-serch.png" alt="Search" /><br /> Search</Link></li>

                                                        { fileName !== 'homePage' ? <li className="nav-item"><Link to={"/cart"} className="nav-link">
                                                            <img src="/assets/images/n-cart.png" alt="Cart" />
                                                            {/* <div className='position-relative'>
                                                                <span className='text-danger new-cart-count'>
                                                                   {typeof cartCount !== 'undefined' ? cartCount : cartCountState}
                                                                </span>
                                                                </div> */}
                                                            <br /> Cart</Link>
                                                        </li> : '' }

                                                    </div>
                                                    <div>

                                                        {token ?
                                                            <div className="n-user d-flex align-items-center mt-3 my-lg-0 profile d-flex text-start" data-bs-target="#exampleModal" data-bs-toggle="offcanvas">
                                                                <img src="/assets/images/profile.png" alt="Profile" />
                                                                <a>
                                                                    <h4 style={{ fontSize: "14px", color: "#000", fontWeight: "600" }} className='ps-2'>{userName || 'User'}</h4>
                                                                    <p style={{ fontSize: "12px", color: "#000" }} className='ps-2'>My Account</p>
                                                                </a>
                                                            </div>
                                                            :
                                                            <div className='n-user d-flex align-items-center mt-3 my-lg-0 border-lg-0'>
                                                                <img src="/assets/images/n-user.png" alt="User" />
                                                                <button type="button" style={{ fontWeight: '600' }}>
                                                                    <a data-bs-target="#loginModal" data-bs-toggle="offcanvas">Sign In</a> / <a data-bs-target="#signupModal" data-bs-toggle="offcanvas">Sign Up</a>
                                                                </button>
                                                            </div>
                                                        }
                                                        {/* <h4><button data-bs-target="#loginModal" data-bs-toggle="offcanvas">Sign in</button></h4> */}
                                                    </div>
                                                    <Link to={'/product'}>
                                                        <button className="order-btn mt-3 my-lg-0">ORDER ONLINE</button>
                                                    </Link>
                                                    
                                                </ul>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </header>
                    </>
                    {/* : (fileName === 'product') ? */}
                    {/* <header id="header" className="main-header">
                                 <div className="container">
                                     <div className="menu-container">
                                         <div className="heading row justify-content-between align-items-center">
                                             <div className="row align-items-center">
                                                 <div className="radio-btn">
                                                     <input type="radio" id="productSpecifyColor" name="productDelivery" onChange={() => deliveryTypeHandleChange('delivery')} checked={deliveryType === 'delivery'} />
                                                     <label htmlFor="productSpecifyColor">Delivery</label>
                                                     <input type="radio" id="productPickUp" name="productDelivery" onChange={() => deliveryTypeHandleChange('take_out')} checked={deliveryType === 'take_out'} />
                                                     <label htmlFor="productPickUp">Take Away</label>
                                                 </div>
                                                 <div className="add" data-bs-target={`#${deliveryType === 'delivery' ? 'locationModal1' : 'locationModal'}`} data-bs-toggle="offcanvas" style={{ cursor: "pointer" }}>
                                                     <img src="/assets/images/location.png" alt="Location" />
                                                     <p> {!nearestOutlet ? 'Moti Begumwadi, Nanpura, Surat' : nearestOutlet.name} </p>
                                                     <img src="/assets/images/edit.png" alt="Edit" />
                                                 </div>
                                             </div>
                                             <div className='d-flex align-items-center'>
                                                 <div className='me-3'>
                                                     <Link to={"/events"}>
                                                         <div className='position-relative text-black mb-3 mb-md-0' style={{ fontWeight: 600 }}>
                                                             Media/Events
                                                         </div>
                                                     </Link>
                                                 </div>
                                                 {token ?
                                                     <div className="profile" data-bs-target="#exampleModal" data-bs-toggle="offcanvas">
                                                         <a>
                                                             <h4>{userName || 'User'}</h4>
                                                             <p>My Account</p>
                                                         </a>
                                                     </div>
                                                     :
                                                     <div>
                                                         <button type="button" style={{ fontWeight: '600' }}>
                                                             <a data-bs-target="#loginModal" data-bs-toggle="offcanvas">Login</a> / <a data-bs-target="#signupModal" data-bs-toggle="offcanvas">signUp</a>
                                                         </button>
                                                     </div>
                                                 }
                                             </div>
                                         </div>
                                         <nav className="navbar navbar-expand-md navbar-dark">
                                             <div className="container">
                                                 <a className="navbar-brand" href="/">
                                                     <img src={logo} alt="Logo" />
                                                 </a>
                                                 <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample05" aria-controls="navbarsExample05" aria-expanded="false" aria-label="Toggle navigation">
                                                     <span className="navbar-toggler-icon"></span>
                                                 </button>
                                                 <div className="collapse navbar-collapse" id="navbarsExample05">
                                                     <ul className="navbar-nav m-auto pl-0">
                                                         <li className="nav-item">
                                                             <a className={`nav-link ${nearestOutlet.outletType && nearestOutlet.outletType.toLowerCase() === 'indo chinese' ? '' : 'd-none'}`} onClick={() => navigate(`/product`)}>INDO CHINESE</a>
                                                         </li>
                                                         <li className="nav-item">
                                                             <a className={`nav-link ${nearestOutlet.outletType && nearestOutlet.outletType.toLowerCase() === 'indo chinese express' ? '' : 'd-none'}`} onClick={() => navigate(`/product`)}>INDO CHINESE - EXPRESS</a>
                                                         </li>
                                                     </ul>
                                                     <ul className="navbar-nav">
                                                         <li className="nav-item me-3">
                                                             <input type="text" onChange={(e) => { setSearchTextParams(e.target.value); }} name='Search' placeholder="Search" />
                                                             <img className='serch-btn' src="/assets/images/serch-icon.png" alt="Search" />
                                                         </li>
                                                         <li className="nav-item d-flex align-items-center" style={{ width: "30px", height: "30px" }}>
                                                             <a href="/cart">
                                                                 <div className='position-relative cart-icon-conatiner'>
                                                                     <img src='/assets/images/cart.png' alt="Cart" />
                                                                     <p className='cart_count'>
                                                                         {cartCount}
                                                                     </p>
                                                                 </div>
                                                             </a>
                                                         </li>
                                                     </ul>
                                                 </div>
                                             </div>
                                         </nav>
                                     </div>
                                 </div>
                             </header> */}
                    {/* : */}
                    {/* <header id="header" className="main-header profile">
                                 <div className="container">
                                     <div className="menu-container">
                                         <nav className="navbar m-auto navbar-expand-md navbar-dark">
                                             <div className="container">
                                                 <a className="navbar-brand" href="/">
                                                     <img src="/assets/images/logo.png" alt="Logo" />
                                                 </a>
                                                 <div className="nav navbar-nav navbar-right" id="navbarsExample05">
                                                     <div className="account">
                                                         <ul className="navbar-nav pe-3 flex-row">
                                                             <li className="nav-item d-flex align-items-center">
                                                                 <Link to={"/events"}>
                                                                     <div className='position-relative me-3'>
                                                                         <p className='text-black' style={{ fontWeight: 600 }}>Media/Events</p>
                                                                     </div>
                                                                 </Link>
                                                             </li>
                                                             <li className="nav-item d-flex align-items-center" style={{ width: "30px", height: "30px" }}>
                                                                 <a href="/cart">
                                                                     <div className='position-relative cart-icon-conatiner'>
                                                                         <img src='/assets/images/cart.png' alt="Cart" />
                                                                         <p className='cart_count'>
                                                                             {typeof cartCount !== 'undefined' ? cartCount : cartCountState}
                                                                         </p>
                                                                     </div>
                                                                 </a>
                                                             </li>
                                                         </ul>
                                                         {token ?
                                                             <div className="profile d-flex" data-bs-target="#exampleModal" data-bs-toggle="offcanvas">
                                                                 <img src="/assets/images/profile.png" alt="Profile" />
                                                                 <a>
                                                                     <h4 style={{ fontSize: "14px", color: "#000", fontWeight: "600" }}>{userName || 'User'}</h4>
                                                                     <p style={{ fontSize: "12px", color: "#000" }}>My Account</p>
                                                                 </a>
                                                             </div>
                                                             :
                                                             <div>
                                                                 <button type="button" style={{ fontWeight: '600' }}>
                                                                     <a data-bs-target="#loginModal" data-bs-toggle="offcanvas">Login</a> / <a data-bs-target="#signupModal" data-bs-toggle="offcanvas">signUp</a>
                                                                 </button>
                                                             </div>
                                                         }
                                                     </div>
                                                 </div>
                                             </div>
                                         </nav>
                                     </div>
                                 </div>
                             </header> */}
                    {/* } */}

                    <div className="payment-modal offcanvas offcanvas-end" id="exampleModal" tabIndex="-1" role="dialog">
                        <div className="offcanvas-body p-5">
                            <div className="topic">
                                <h2>Wok on fire</h2>
                                <button className="close-icon close" type="button" data-bs-dismiss="offcanvas"><img
                                    src="/assets/images/close.png" alt="Close" /></button>
                            </div>
                            <h6>Wallet Balance</h6>
                            <div className="wallet">
                                <div className="balance">
                                    <img src="/assets/images/wallet-icon.png" alt="Wallet" />
                                    <h4>₹{userData.wallet}</h4>
                                </div>
                                <button className="add" data-bs-toggle="offcanvas" data-bs-target="#addWallet">+ ADD</button>
                            </div>
                            <div className="ProfileSidenavList">
                                <p><a onClick={() => { window.$("#exampleModal").offcanvas('hide'); navigate("/profile/pro"); }}>Profile</a></p>
                                <p><a onClick={() => { window.$("#exampleModal").offcanvas('hide'); navigate("/profile/order"); }}>Order</a></p>
                                <p><a onClick={() => { window.$("#exampleModal").offcanvas('hide'); navigate("/profile/wallet"); }}>Wallet</a></p>
                                <p><a onClick={() => { window.$("#exampleModal").offcanvas('hide'); navigate("/profile/add"); }}>Address</a></p>
                                <p><a onClick={() => { window.$("#exampleModal").offcanvas('hide'); navigate("/profile/fav"); }}>Favorite</a></p>
                                <p onClick={(e) => {
                                    e.preventDefault();
                                    logOutFunction();
                                }}>Sign Out</p>
                            </div>
                        </div>
                    </div>

                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="loginModal" aria-labelledby="loginModalLabel">
                        <div className="offcanvas-header justify-content-end">
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body p-5 pt-4">
                            <img src="/assets/images/dummy.png" alt="Dummy" />
                            <h4>Login</h4>
                            <h6>or<a className="create" data-bs-toggle="offcanvas" data-bs-target="#signupModal"> Create an account</a></h6>
                            <p>Login with your valid mobile number</p>
                            <form ref={loginFormRef} id="loginForm">
                                <div className="input-label-group mb-3">
                                    <label>Mobile Number</label>
                                    <input className="input-field m-0 newerror" type="text" name='mobile_number' id='mobile_number' autoComplete='off' onChange={limitHandleChange} value={mobileNumber} />
                                </div>
                                <button
                                    type="submit"
                                    className="otp-btn"
                                    disabled={loginLoading}
                                >
                                    {   loginLoading
                                            ? <div className="loading-state btn-loading-state">
                                                <div className="loading btn-loading"></div>
                                                </div>
                                            : 'SENT OTP'
                                    }
                                </button>
                            </form>
                            <h5>By Continuing you agree to the <a className="terms"> Terms of Services</a> and  <a className="terms">Privacy policy</a></h5>
                        </div>
                    </div>

                    <div className="otp offcanvas offcanvas-end" id="otpModal" role="dialog">
                        <div className="offcanvas-body p-5">
                            <img src="/assets/images/dummy.png" alt="Dummy" />
                            <h4>Verify OTP</h4>
                            <p>Enter 6 digit verification code sent by SMS {userNumber}</p>
                            <form ref={otpFormRef} id="otpForm">
                                <div className='otp-digit-group' data-group-name="digits">
                                    <input className="input-field otp-get-data" type="text" id="digit-1" name="digit-1" data-next="digit-2" autoComplete="off" />
                                    <input className="input-field otp-get-data" type="text" id="digit-2" name="digit-2" data-previous="digit-1" data-next="digit-3" autoComplete="off" />
                                    <input className="input-field otp-get-data" type="text" id="digit-3" name="digit-3" data-previous="digit-2" data-next="digit-4" autoComplete="off" />
                                    <input className="input-field otp-get-data" type="text" id="digit-4" name="digit-4" data-previous="digit-3" autoComplete="off" />
                                </div>
                                <button type='button' style={{ cursor: "pointer" }} onClick={resendOtp}>
                                    <h6>Resend OTP</h6>
                                </button>
                                <button
                                    type="submit"
                                    className="otp-btn"
                                    disabled={loginLoading}
                                >
                                    {   loginLoading
                                            ? <div className="loading-state btn-loading-state">
                                                <div className="loading btn-loading"></div>
                                                </div>
                                            : 'Verify & Continue'
                                    }
                                </button>
                            </form>
                        </div>
                    </div >

                    <div className="offcanvas offcanvas-end" id="signupModal" role="dialog">
                        <div className="offcanvas-header justify-content-end">
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body p-5 pt-4">
                            <div>
                                <img src="/assets/images/dummy.png" alt="Dummy" />
                                <h4>Sign Up</h4>
                                <h6>or<a className="create" data-bs-toggle="offcanvas" data-bs-target="#loginModal">  Login to your Account</a></h6>
                                <p>Fill your Correct Details</p>
                            </div>
                            <div>
                                <form ref={signUpFormRef} id="signUpForm">
                                    <div className="input-label-group mb-3">
                                        <label>Name</label>
                                        <input className="input-field newerror mb-0" type="text" name='first_name' onChange={(e) => setSignUpData({...signUpData, first_name: e.target.value})} value={signUpData.first_name} />
                                    </div>
                                    <div className="input-label-group mb-3">
                                        <label>Mobile Number</label>
                                        <input className="input-field newerror mb-0" type="text" name='mobile_number' autoComplete='off' onChange={mobileNumberLimit} value={signUpData.mobile_number} />
                                    </div>
                                    <div className="input-label-group mb-3">
                                        <label>Email Id</label>
                                        <input className="input-field newerror mb-0" type="text" name='email' onChange={(e) => setSignUpData({...signUpData, email: e.target.value})} value={signUpData.email} />
                                    </div>
                                    <button
                                        type="submit"
                                        className="otp-btn"
                                        disabled={loginLoading}
                                    >
                                        {   loginLoading
                                                ? <div className="loading-state btn-loading-state">
                                                    <div className="loading btn-loading"></div>
                                                    </div>
                                                : 'Sign Up'
                                        }
                                    </button>
                                </form>
                            </div>
                            <h5>By Continuing you agree to the <a className="terms"> Terms of Services</a> and  <a className="terms">Privacy policy</a></h5>
                        </div>
                    </div>

                    <div className="offcanvas offcanvas-end location-not-exists" data-bs-backdrop="static" tabIndex="-1" id="locationModal" role="dialog">
                        <div className="offcanvas-body p-5">
                            <div className="topic">
                                <h2>Select your Location</h2>
                                {showModal ? '' :
                                    <button className="close-icon close" type="button" data-bs-dismiss="offcanvas"><img src="/assets/images/close.png" alt="Close" /></button>}
                            </div>
                            <div className="radio-btn">
                                { nearestOutlet.delivery == 'Enabled' || typeof nearestOutlet.delivery == typeof undefined ? <><input type="radio" id="modelSpecifyColor" name="pickUp" onChange={() => deliveryTypeHandleChange('delivery')} checked={deliveryType === 'delivery'} data-bs-target="#locationModal1" data-bs-toggle="offcanvas" style={{ cursor: "pointer" }} />
                                <label htmlFor="modelSpecifyColor">Delivery</label></> : "" }
                                
                                { nearestOutlet.pickup == 'Enabled' || typeof nearestOutlet.pickup == typeof undefined ? <><input type="radio" id="modelPickUp" name="pickUp" onChange={() => deliveryTypeHandleChange('take_out')} checked={deliveryType === 'take_out'} />
                                <label htmlFor="modelPickUp">Take Away</label></> : "" }
                                
                            </div>
                            <p>Which store would you like to Pickup/Dine-in order from</p>
                            {locationLoading ? <div className="loading-state">
                                <div className="loading"></div>
                            </div> : <>
                                <form ref={pickUpLocationFormRef} id='pickUpLocation'>
                                    <div className="select-city">
                                        <select className="city newerror" name='city' value={userData.city ? userData.city : nearestOutlet.city ? nearestOutlet.city.toLowerCase() : ''} onChange={(e) => setOutletByCity(e.target.value)}>
                                            <option value=''>Select City</option>
                                            {
                                                city.length > 0 ? city.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item}>{item.charAt(0).toUpperCase() + item.slice(1)}</option>
                                                    )
                                                }) : ''
                                            }
                                        </select>
                                        <div className="add">
                                            <img src="/assets/images/serch.png" alt="Add" />
                                        </div>
                                    </div>
                                    <div className="select-area">
                                        <select className="area newerror" name='area' value={userData.deliveryOutlet ? userData.deliveryOutlet : nearestOutlet.id} onChange={(e) => {
                                            setUserData({ ...userData, deliveryOutlet: e.target.value });
                                            setArea(outlets.all.filter(x => x._id === e.target.value)[0]);
                                        }}>
                                            <option value=''>Select Area</option>
                                            {renderOptions()}
                                        </select>
                                        <div className="sug">
                                            <img src="/assets/images/serch.png" alt="Search" />
                                        </div>
                                    </div>
                                    {
                                        !isEmptyObject(area) ?
                                            <>
                                                <h6>Pickup/Dine-in Your Order from Restaurant at</h6>
                                                <div className="add-box">
                                                    <img src="/assets/images/serch.png" alt="Search" />
                                                    <div className="txt">
                                                        <h5>{area.city.toLowerCase().charAt(0).toUpperCase() + area.city.toLowerCase().slice(1)}</h5>
                                                        <p>{area.masterName ? area.masterName : area.name}</p>
                                                        <input type="hidden" name='name' value={area.masterName ? area.masterName : area.name} />
                                                        <input type="hidden" name='outletType' value={area.outletType} />
                                                        <input type="hidden" name='masterName' value={area.masterName ? area.masterName : area.name} />
                                                        <input type="hidden" name='delivery' value={area.delivery ? area.delivery : 'Enabled'} />
                                                        <input type="hidden" name='pickup' value={area.pickup ? area.pickup : 'Enabled'} />
                                                    </div>
                                                </div>
                                            </> : ''
                                    }
                                    <button type='submit' className="pro-btn">PROCEED</button>
                                </form>
                            </>}
                        </div>
                    </div>

                    <div className="offcanvas offcanvas-end location-not-exists" data-bs-backdrop="static" tabIndex="-1" id="locationModal1" role="dialog">
                        <div className="offcanvas-body p-5">
                            <div className="topic">
                                <h2>Select your Location</h2>
                                {showModal ? '' :
                                    <button className="close-icon close" type="button" data-bs-dismiss="offcanvas"><img src="/assets/images/close.png" alt="Close" /></button>}
                            </div>
                            <div className="radio-btn">
                                
                                { nearestOutlet.delivery == 'Enabled' || typeof nearestOutlet.delivery == typeof undefined ? <><input type="radio" id="modelLSpecifyColor" name="modelDelivery" onChange={() => deliveryTypeHandleChange('delivery')} checked={deliveryType === 'delivery'} />
                                <label htmlFor="modelLSpecifyColor">Delivery</label> </> : "" }
                                
                                { nearestOutlet.pickup == 'Enabled' || typeof nearestOutlet.pickup == typeof undefined ? <><input type="radio" id="modelLPickUp" name="modelDelivery" onChange={() => deliveryTypeHandleChange('take_out')} checked={deliveryType === 'take_out'} data-bs-target="#locationModal" data-bs-toggle="offcanvas" style={{ cursor: "pointer" }} />
                                <label htmlFor="modelLPickUp">Take Away</label></> : "" }
                                
                            </div>
                            <p>We will deliver right to your door</p>
                            <div className='position-relative'>
                                <input className="serch" type="text" placeholder="Search Area/Locality" onChange={(e) => { deliveryOutletSearch(e) }} />
                                <button className="locate" onClick={() => {
                                    localStorage.removeItem('location');
                                    window.location.reload();
                                }}>Locate me</button>
                            </div>
                            {locationLoading ? <div className="loading-state">
                                <div className="loading"></div>
                            </div> : <>
                                <div className="box">
                                    <h4>{locationSuggestionData[0] ? 'SUGGESTIONS' : ''}</h4>
                                    {locationSuggestionData.map((item, index) => {
                                        return (
                                            <div className="area" style={{ cursor: "pointer" }} key={index} onClick={() => changeDeliveryOutlet(item)}>
                                                <img src="/assets/images/add-serch.png" alt="Add" />
                                                <div className="area-name">
                                                    <h5>{item.city}</h5>
                                                    <p>{item.masterName ? item.masterName : item.name}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="box">
                                    <h4>RECENT SEARCH</h4>
                                    {recentLocation.length > 0 ? recentLocation.map((item, index) => {
                                        return (
                                            <div className="recent" key={index}>
                                                <div className="old-serch" style={{ cursor: 'pointer' }} onClick={() => changeDeliveryOutlet(item)}>
                                                    <img src="/assets/images/location.png" alt="Location" />
                                                    <div className="area-name">
                                                        <h5>{item.city}</h5>
                                                        <p>{item.masterName ? item.masterName : item.name}</p>
                                                    </div>
                                                </div>
                                                <img src="/assets/images/delete.png" style={{ cursor: "pointer" }} onClick={(e) => deleteLocalStorageSearch(item.id)} alt="Delete" />
                                            </div>
                                        )
                                    }) : ''}
                                </div>
                            </>}
                        </div>
                    </div>

                    <div className="payment-modal offcanvas offcanvas-end" id="addWallet" tabIndex="-1" role="dialog">
                        <div className="offcanvas-body p-5">
                            <div className="topic">
                                <h2>Add Wallet</h2>
                                <button className="close-icon close" type="button" data-bs-dismiss="offcanvas"><img src="/assets/images/close.png" alt="Close" /></button>
                            </div>
                            <form ref={addWalletRef} id='addWalletForm'>
                                <div className='mb-2'>
                                    <input type="number" className="input-tag-design newerror mb-0" name='amount' id='amount' placeholder="Enter Amount" />
                                </div>
                                <button type='submit' className="add-money">ADD MONEY</button>
                            </form>
                        </div>
                    </div>

                    <div className="payment-modal offcanvas offcanvas-end" id="exampleModal2" tabIndex="-1" role="dialog">
                        <div className="offcanvas-body p-5">
                            <div className="topic">
                                <h2>My Profile</h2>
                                <button className="close-icon close" type="button" data-bs-dismiss="offcanvas"><img src="/assets/images/close.png" alt="Close" /></button>
                            </div>
                            <input type="text" placeholder="Name" />
                            <span></span>
                            <input type="text" placeholder="Mobile No." />
                            <input type="text" placeholder="Email" />
                            <button className="add-money">SAVE & CONTINUE</button>
                        </div>
                    </div>

                    <div className="profile-data offcanvas offcanvas-end" id="exampleModalProfile" tabIndex="-1" role="dialog">
                        <div className="offcanvas-body p-5">
                            <div className="topic">
                                <h2>My Profile</h2>
                                <button className="close-icon close" type="button" data-bs-dismiss="offcanvas"><img src="/assets/images/close.png" alt="Close" /></button>
                            </div>
                            <div className="input-box name">
                                <div className="box">
                                    <img src="/assets/images/man.png" alt="User" />
                                </div>
                                <input type="text" placeholder="Enter Name" />
                            </div>
                            <div className="input-box number">
                                <div className="box">
                                    +91
                                </div>
                                <input type="number" placeholder="Mobile No." />
                            </div>
                            <div className="input-box mail">
                                <div className="box">
                                    <img src="/assets/images/mail.png" alt="Mail" />
                                </div>
                                <input type="text" placeholder="Email" />
                            </div>
                            <button className="save-btn">SAVE & CONTINUE</button>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default Header;