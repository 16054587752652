import React, { useEffect, useState, useRef } from 'react'
import Header from '../UI/Header/Header'
import Footer from '../UI/Footer/Footer'
import { useNavigate } from 'react-router-dom'


const TermsCondition = () => {
    const navigate = useNavigate();
    const sectionRef = useRef();
    const [nearestOutlet, setNearestOutlet] = useState(JSON.parse(localStorage.getItem('location')) || {});

    useEffect(() => {
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    })

    return (
        <>
            <div ref={sectionRef}>
                <Header fileName={'termsCondition'} nearestOutletParams={nearestOutlet} setNearestOutletParams={setNearestOutlet}  />
                <div className="policy">
                    <div className="container">
                        <button className="back" onClick={() => navigate('/')}>Back to Home</button>
                        <div className="title">
                            <h5>Terms & Conditions</h5>
                        </div>
                        <div className="policy-detail">
                            <p>1.	Introduction These Terms and Conditions govern your use of our website and mobile application. By accessing or using our website or application, you agree to be bound by these Terms and Conditions.</p>
                            <p>2.	Intellectual Property All content on our website and application, including text, images, graphics, logos, and software, is the property of MR MISCHIEF FOOD CO.(WOF) and is protected by copyright and other intellectual property laws.</p>
                            <p>3.	Use of Website and Application You agree to use our website and application only for lawful purposes and in a manner consistent with all applicable laws and regulations.</p>
                            <p>4.	Account Registration In order to access certain features of our website and application, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>
                            <p>5.	Orders and Payments By placing an order through our website or application, you agree to pay the specified price for the products or services ordered. Payment may be made through the methods specified on our website or application.</p>
                            <p>6.	Disclaimer of Warranties Our website and application are provided on an "as is" and "as available" basis, without any warranties of any kind, express or implied. We do not warrant that our website or application will be uninterrupted or error-free, or that any defects will be corrected.</p>
                            <p>7.	Limitation of Liability In no event shall [Your Company Name] be liable for any indirect, incidental, special, or consequential damages arising out of or in any way connected with your use of our website or application.</p>
                            <p>8.	Governing Law These Terms and Conditions shall be governed by and construed in accordance with the laws of India.</p>
                            <p>9.	Changes to Terms and Conditions We reserve the right to modify these Terms and Conditions at any time. Any changes will be effective immediately upon posting on our website or application. Your continued use of our website or application after any such changes constitutes your acceptance of the new Terms and Conditions.</p>
                            {/* <p>10.	WOF shall not be liable for any acts or omissions on the part of the Restaurant Partner/Store(s), including deficiency in service, wrong delivery of order/order mismatch, quality, incorrect pricing, deficient quantity, time taken to prepare or deliver the order, etc.</p> */}
                            <p>10.	Please note that some of the food and beverages or Products may be suitable for certain ages only. You should check the dish you are ordering and read its description, if provided, prior to placing your order. WOF shall not be liable in the event the food and beverages or the Product ordered by You does not meet your dietary or any other requirements and/or restrictions.</p>
                            <p>11.	While placing an order you shall be required to provide certain details, including without limitation, contact number and delivery address. You agree to take particular care when providing these details and warrant that these details are accurate and complete at the time of placing an Order. By providing these details, you express your acceptance to WOF’s terms and privacy policies.</p>
                            <p>12.	You or any person instructed by you shall not resell food and beverages or Products purchased via the WOF Platform.</p>
                            <p>13.	The total price for food ordered, including the Delivery Charges and other charges, will be displayed on the WOF Platform when you place your order, which may be rounded up to the nearest amount. Customers shall make full payment towards such food or Products ordered via the WOF Platform.</p>
                            <p>14.	Delivery periods/Takeaway time quoted at the time of ordering are approximate only and may vary.</p>
                            <p>15.	Any amount that may be charged to you by WOF over and above the order value, shall be inclusive of applicable taxes.</p>
                            <p>We reserve the right, at any time and without prior notice, to remove, block, or disable access to any Content that we, for any reason or no reason, consider to be objectionable, in violation of the Terms or otherwise harmful to the Services or our Customers in our sole discretion. Subject to the requirements of applicable law, we are not obligated to return any of Your Content to you under any circumstances. Further, the WOF reserves the right to delete any images and pictures forming part of Customer Content, from such Restaurant's listing page at its sole discretion.</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default TermsCondition